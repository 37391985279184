import {Card, Col,Container,Row} from 'react-bootstrap';
import BotonCustomLink from '../../components/all/BotonCustomLink';



export default function Seccion5(){
    
    return(
        <section id='inicio5'>
            <div className='container-fluid cyan-back seccion-min-centrado'>
                <Container className='container-body-2 d-flex justify-content-center'>
                    <Row className='d-flex justify-content-center'>
                        <Col md={12}>
                            <Card className='shadow p-3 rounded text-center border-0' data-aos="fade-up" data-aos-offset="200"  data-aos-duration="500">
                                <Card.Body style={{paddingLeft:'50px',paddingRight:'50px'}}>
                                    <h3>Experiencias de pago exitosas y seguras</h3>
                                    <div className='boton-centrado mt-4'>
                                    <BotonCustomLink text='Descubrí por qué' link='/pagos-exitosos-y-seguros' tipo='pink'/>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    ) 
}