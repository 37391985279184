import {Card} from 'react-bootstrap';

export default function ServiceIconCard({title,text,icon}){
    return(
         <Card className='border-0 serviceIconCard' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                <Card.Body className = 'text-center'>
                <Card className='border-0 iconCard'>
                <Card.Body>
                <img src={icon} alt={icon} width ='80px' height='auto'/>
                </Card.Body>
                </Card>
                    <h3 className='parrafo'  data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">{title}</h3>
                    <p className='parrafo'  data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">{text}</p>
                </Card.Body>
            </Card>
    ) 
}