import {Col,Container,Row} from 'react-bootstrap';
import Backgroundblock2 from '../../components/img/photos/seccion2-cabecera-background.png';



export default function Seccion2(){

    const text=[
        {texto:'Pagás altos costos de comisión.'},
        {texto:'Tenés muchos rechazos de pagos con tarjetas.'},
        {texto:'Cada conciliación bancaria es una pérdida de tiempo.'},
        {texto:'La recaudación no es tan efectiva como quisieras.'},
        {texto:'No te brinda información pertinente para la toma de decisiones.'}
    ]

    
    return(

        <section id='inicio2'>
            <div className='container-fluid white-back seccion-centrado' style={{backgroundImage:`url(${Backgroundblock2})`}}>
            <Container className='container-body-2'>
                <Row className='d-flex justify-content-center'>
                    <Col md={6} xs={12} data-aos="fade-right" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                        <div className='seccion-2-texto'>
                            <h5>Tu empresa tiene una plataforma de pago, pero...</h5><br/>

                            {text.map((e, index) =>
                            <p  key={`list-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg> {e.texto}
                            </p>
                            
                            )}

                            <br/><h5>O si no contás con una... PAYFUN ES TU SOLUCIÓN</h5>


                        </div>
                    </Col>
                    <Col md={6} xs={12} style={{minHeight:'300px'}} data-aos="fade" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                    <iframe className="embed-responsive-item" title="PayFun" src="https://player.vimeo.com/video/776298833?h=8fec57b5c3" width="100%" height="100%" allow="autoplay; fullscreen; picture-in-picture" sandbox='allow-scripts allow-same-origin allow-presentation' loading='lazy'></iframe>
                    </Col>
                </Row>
            </Container>
            </div>
        </section>
    ) 
}