import {Container,Col,Row} from 'react-bootstrap';
import Metamap from '../../components/img/photos/metamap.png'
import BotonSolicitud from '../../components/all/BotonSolicitud';

export default function Head(){
    return(
    <section id='headboard'>
        <div className="container-fluid headboard" style={{ backgroundColor:'#ff7baf'}}>
            <Container>
                <Row>
                    <Col md={7} xs={12} className='texto'  data-aos="fade-right" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000" style={{color:'white'}}>
                    <h1>Experiencias de pago exitosas y seguras</h1>
                            <h3>En PayFun, nos apasiona brindar experiencias<br/>de pago únicas e innovadoras<br/>a nuestros clientes.</h3>        
                    <br/>
                        <BotonSolicitud positivo='false'/>
                    </Col>
                    <Col md={5} xs={12}  data-aos="fade-left" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                    <img style={{'marginTop':'30px'}} className='img-fluid' src={Metamap} alt='cabecera-inicio'/>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
    ) 
}