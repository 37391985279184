import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Enviado from '../components/img/icons/sendForm.png';

export default function Respuesta(){
  
    const {codigo} = useParams();
    const [titulo,setTitulo] = useState('');
    const [texto,setTexto] = useState('');

    useEffect(() =>{
        switch (codigo) {
            case "integrate":
              setTitulo ([titulo, '¡Muchas gracias por interesarse en nuestros servicios!'])
              setTexto ([texto, 'Nos contactaremos a la brevedad.'])
              break;
            case "reclamo":
                setTitulo ([titulo, 'Su reclamo ha sido enviado con éxito'])
                setTexto ([texto, `Recibirá en su casilla de correo una copia con el código del reclamo para hacer el seguimiento.\nSi no lo encuentra revise la carpeta de "Correo no deseado" o "spam"`])
              break;
            default:
                window.location = '/no-encontrada'
              break;
          }

    },[])
    return(
    <div className='notFound' >
        <img width='130' height='130' src={Enviado} alt='enviado' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000"/>
        <h2 data-aos="fade-up" data-aos-offset="50" data-aos-delay="150" data-aos-duration="1000">{titulo}</h2>
        <p className='parrafo' data-aos="fade-up" data-aos-offset="250" data-aos-delay="50" data-aos-duration="1000">{texto}</p>
    </div>
    ) 
}