import {Card, Col,Row} from 'react-bootstrap';



export default function MultiCard(){
    
    return(
        <section id='Multicard'>
            <div className='container-body-2 neutral-div container'>
                <Card className='border-0 customCard videoMargin' data-aos="fade-up" data-aos-offset="50" data-aos-duration="1000">
                <Card.Body className='multicard-body'>
                    <Row>
                        <Col md={6} >
                            <h2  data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">Multiadquirencia</h2>
                            <p  data-aos="fade-up" data-aos-offset="50" data-aos-delay="400" data-aos-duration="1000">Con PayFun, aumentá un 30% los pagos capturados reduciendo el nivel de rechazos con tarjetas.</p>
                        </Col>
                        <Col md={5}>
                            <div className='videoContainer'>
                            <iframe className='embed-responsive-item' title='Payfun' src="https://player.vimeo.com/video/788765091?h=360a7abfab" width="400" height="227"  allow="autoplay; fullscreen; picture-in-picture"></iframe>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
                </Card>
            </div>
        </section>
    ) 
}