import { Container, Col, Row } from "react-bootstrap";
import seccion6FondoCabecera from "../../components/img/photos/seccion6-cabecera-background.png";
import BotonSaberMas from "../../components/all/QuieroSaberMas";

export default function Seccion7() {
  const lista = [
    {
      titulo: "Links de pago",
      link: "/servicios/links-de-pago",
    },
    {
      titulo: "Totems\nInteractivos",
      link: "/servicios/totems-interactivos",
    },
    {
      titulo: "Pagos QR",
      link: "/servicios/pagos-con-qr",
    },
    {
      titulo: "Órdenes de pago\nsimple/múltiples",
      link: "/servicios/ordenes-de-pago",
    },
    {
      titulo: "Pagos en tu\nsitio web",
      link: "/servicios/pagos-en-tu-sitio-web",
    },
    {
      titulo: "Pagos con lector\nde código de barras",
      link: "/servicios/lector-de-codigo-de-barras",
    },
  ];
  return (
    <section id="inicio6">
      <div
        className="container-fluid white-back seccion-centrado"
        style={{ backgroundImage: `url(${seccion6FondoCabecera})` }}
      >
        <Container className="container-body-2">
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <div md={12} className="div-container-servicios"  data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">

                    <h3>
                      Descubrí nuestros Servicios
                    </h3>
                    <br />
                    <br />

                <Row>
                  {lista.map((e, index) => (
                    <Col md={4} xs={6} className="div-servicios" key={`seis-${index}`}  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000">
                        <h5 className="parrafo">{e.titulo}</h5>
                        <BotonSaberMas
                          className="boton-servicios"
                          link={e.link}
                        />
                      </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
