import '../Services.css';
import HeadImage from '../../../components/img/photos/services/mano-con-lector-principal.png'
import Headboard from '../../../components/services/Headboard';
import { Col, Container, Row } from 'react-bootstrap';
import CustomTitle from '../../../components/services/CustomTitle';
import NumberCustomCard from '../../../components/services/NumberCustomCard';
import PlacaFinal from './PlacaFinal';


const CodigoBarras = () =>{
    return (
        <div id='services' className='containerFluid'>

            <Headboard title={`Pagos con lector de\ncódigo de barras`} subtitle={`Cobrá fácilmente en tu mercado o negocio físico`} button={`Quiero gestionar pagos con códigos de barras`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>   
            <Col md ={12} data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <CustomTitle title={`Gestioná pagos con tu lector de código de barras`}/>
                </Col>
            <Col md={12}>
            <Row>
                    <Col md={12}><NumberCustomCard number='1' title={`\nDefiní un código de barras para cada uno de tus productos\n`}/></Col>
                    <Col md={6}><NumberCustomCard number='2' title={`Escaneá el\ncódigo`}/></Col>
                    <Col md={6}><NumberCustomCard number='3' title={`Recibí los pagos\nal instante`}/></Col>
                </Row>
            </Col>
            </Row>
            </Container>
            <PlacaFinal/>    
        </div>
    )
}

export default CodigoBarras