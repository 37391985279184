import { Card, Col, Container, Row,Button,Collapse} from 'react-bootstrap';
import Head from '../components/pagoseguro/Head';
import discoMetamap from '../components/img/photos/disco_metamap.png';
import icons1 from '../components/img/icons/seguridad/s1.png';
import icons2 from '../components/img/icons/seguridad/s2.png';
import icons3 from '../components/img/icons/seguridad/s3.png';
import icons4 from '../components/img/icons/seguridad/s4.png';
import BotonCustom from '../components/all/BotonCustom';
import { useState } from 'react';


const PagoSeguro = () =>{
    const [item0, setItem0] = useState(true);
    const [item1, setItem1] = useState(true);
    return (
        <div id='pagoseguro' className='containerFluid'>
            <Head/>
            <Container className='container-body-2'>
            <Row>    
            <Col md={12} className="textoPagoSeguro parrafo" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1000"><p><strong>{`Como expertos en el rubro Fintech, entendemos la importancia de brindarte\ntransacciones que sean seguras, simples y rápidas.`}</strong></p></Col>
            
            <Col md={12} className="textoPagoSeguro parrafo" data-aos="fade-up" data-aos-offset="300" data-aos-delay="100" data-aos-duration="1000"><p>{`Nuestra filosofía se basa en la `}<strong>{`seguridad\n`}</strong>{`diferenciándonos de otras pasarelas de pago.`}</p></Col>
            
            <Col md={12}  className="discoMeta" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1000"><img className='img-fluid' src={discoMetamap} alt='cabecera-inicio'/>
            
            <Card className='border-0 textCard textCardMargin1' data-aos="fade-up" data-aos-offset="300" data-aos-delay="300" data-aos-duration="1000">
                <Card.Body className = 'text-center'>
                    <p className='parrafo' data-aos="fade-up" data-aos-offset="300" data-aos-delay="400" data-aos-duration="1000">Para ello, trabajamos a la par de MetaMap, que gracias a su avanzada tecnología, podemos reducir el fraude al identificar usuarios reales en solo unos segundos. Garantizando confianza y seguridad en tu plataforma.</p>
                </Card.Body>
            </Card>
            </Col>
            <Col md={12}  className="textoPagoSeguro parrafo" data-aos="fade-up" data-aos-offset="300" data-aos-delay="100" data-aos-duration="1000"><p>{`Podés optar por uno o varios de los siguientes servicios:`}</p>

            {/* ------------------------------------------ */}
            <Col md={12} className='textoPagoSeguromt-5 mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="300" data-aos-delay="300" data-aos-duration="1000">
                    <Button className='validacion-button' onClick={() => setItem0(!item0)} aria-controls="example-collapse-text" aria-expanded={item0} style={{textAlign:'left'}}>
                    {item0===true?'▲':'▼'} Validaciones de Cumplimiento
                    </Button>
                    <Collapse in={item0} className='validacion-body'>
                        <div id="cumplimiento" >
                        <Row style={{margin:'0px 15px 0px 15px'}}>
                            <Col style={{margin:'0px -10px 0px -10px'}} md={6} xs={12}><img className='img-fluid' src={icons1} alt='iconos-1'/></Col>
                            <Col style={{margin:'0px -10px 0px -10px'}} md={6} xs={12}><img className='img-fluid' src={icons2} alt='iconos-2'/></Col>
                        </Row>    
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-3 d-grid gap-2' data-aos="fade-up" data-aos-offset="300" data-aos-delay="400" data-aos-duration="1000">
                    <Button className='validacion-button' onClick={() => setItem1(!item1)} aria-controls="example-collapse-text" aria-expanded={item1} style={{textAlign:'left'}}>
                    {item1===true?'▲':'▼'} Validaciones financieras
                    </Button>
                    <Collapse in={item1} className='validacion-body'>
                        <div id="financieras" >
                        <Row style={{margin:'0px 15px 0px 15px'}}>
                            <Col style={{margin:'0px -10px 0px -10px'}} md={6} xs={12}><img className='img-fluid' src={icons3} alt='iconos-3'/></Col>
                            <Col style={{margin:'0px -10px 0px -10px'}} md={6} xs={12}><img className='img-fluid' src={icons4} alt='iconos-4'/></Col>
                        </Row>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
            </Col>
            <Col md={12}  className="textoPagoSeguro parrafo" data-aos="fade-up" data-aos-offset="300" data-aos-delay="100" data-aos-duration="1000"><p>{`Las empresas que nos eligen como su pasarela de pago, operan bajo la\ntranquilidad de `}<strong>{`estar en buenas manos`}</strong>.</p></Col>

            <Col md={12}>
            <Card className='border-0 textCard textCardMargin2' data-aos="fade-up" data-aos-offset="300" data-aos-delay="300" data-aos-duration="1000">
                <Card.Body className = 'text-center'>
                    <p data-aos="fade-up" data-aos-offset="300" data-aos-delay="400" data-aos-duration="1000">Te ofrecemos un servicio que se enfoca exclusivamente en lo importante: <strong>la seguridad de tu recaudación.</strong> Vas a estar respaldado por un equipo de expertos en seguridad, dispuestos a ayudarte en todo momento para resolver tus consultas.</p>
                </Card.Body>
            </Card>
            </Col>
            <Col md={12}  className="textoPagoSeguro parrafo" data-aos="fade-up" data-aos-offset="300" data-aos-delay="100" data-aos-duration="1000"><p>{`En PayFun, podés garantizar que tus transacciones estarán seguras en todo momento.`}</p><br/><BotonCustom text='Solicitar una prueba ahora' tipo='pink'/></Col>
            </Row>
            </Container>
            
            
        </div>
    )
}

export default PagoSeguro