import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BotonCustomLink from '../components/all/BotonCustomLink';
import legales from '../uploads/legales.txt'

 

export default function TerminosCondiciones(){

    const [texto,setTexto] = useState("");
    

    useEffect (()=>{
        fetch(legales)
        .then(r => r.text())
        .then(text => { setTexto(text) });
    })

    return(
        <div id='terminos' className='containerFluid'>
            <Container className='container-body-2'>
                <Row>
                    <Col md={12} className='text-center mb-5 mt-5'>
                        <h2>Términos y Condiciones</h2>
                        <h6>(validos al {texto.substring(0,10)})</h6>
                    </Col>
                    <Col md={12} className='mb-5 mt-2'>
                        <p className='parrafo'>{texto.slice(10)}</p>
                    </Col>
                    <Col md={12} className='mb-5 mt-2 text-center'>
                        <BotonCustomLink text='Imprimir, visualizar o descargar en pdf' link='/terminos-y-condiciones-pdf' tipo='blue'/>
                    </Col>
                </Row>
                
            </Container>
        </div>
    ) 
}