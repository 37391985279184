import {Card} from 'react-bootstrap';

export default function TitleCard({title}){
    return(
        <Card className='border-0 titleCard'  data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000">
            <Card.Body>
                <h5 className="text-center text-uppercase">{title}</h5>          
            </Card.Body>
        </Card>
         
        
    ) 
}