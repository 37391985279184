import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import React, { useState, useRef} from 'react';
import { Container,Row,Col } from "react-bootstrap";
import * as Yup from "yup";
import Localidades from "../../../components/all/Localidades";
import ReCAPTCHA from "react-google-recaptcha";

const dniPat = '[0-9]+$';
const telPat = '[0-9-()+]+$';


const FormSchema = Yup.object().shape({
    
    nombreApellido: Yup.string()
        .required("Este campo es requerido")
        .min(8, "Campo demasiado corto"),
    doc: Yup.string().matches(dniPat, "Coloque un DNI válido")
        .required("El DNI es requerido")
        .max(8, "Solo 8 dígitos (sin puntos)")
        .min(8, "El número de DNI es incorrecto"),  
    email: Yup.string()
        .email("El email es inválido")
        .required("El campo Email es requerido"),
    telefono: Yup.string().matches(telPat, "Se requiere un teléfono válido")
        .min(10,'mínimo 10 dígitos')
        .max(20,'20 dígitos es los máximo')
        .required('Un telefono de contacto es necesario'),
    provincia: Yup.string()
        .required("Este campo es requerido"),
    localidad: Yup.string()
        .required("Este campo es requerido"),
    piso: Yup.string()
        .notRequired(),
    domicilio: Yup.string()
        .min(8, "Coloque su domicilio completo")
        .required("Este campo es requerido"),
    tarjeta: Yup.string()
        .required("Este campo es requerido"),
    numero: Yup.string().matches(dniPat, "Solo números")
        .max(4, "Solo 4 dígitos")
        .required("Este campo es requerido"),
    operacion: Yup.string()
        .required("Este campo es requerido"),
    entidad: Yup.string()
        .required("Este campo es requerido"),                     
    
    comentario: Yup.string()
                .max(260,'Ha excedido la cantidad de caracteres (260). Por favor sea breve.')
                .notRequired()
  });



  export default function Tarjetas2({data}){
    const [provincia,setProvincia] = useState('');
    const[envio,setEnvio]=useState({codigo:data.codigo,subcodigo:data.subcodigo,concepto:data.concepto,subtema:data.subtema});
    const[nocaptcha,setNocaptcha] = useState('');
    const captchaRef = useRef(null);
    
      function handleSubmit(values) {
        const codigoToken = {'token':captchaRef.current.getValue()}
            fetch (process.env.REACT_APP_GOOGLE, {
                method: 'POST',
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json' 
                },
                mode: 'cors',
                body: JSON.stringify(codigoToken)
              })
            .then(res => res.json())
            .then(res => {
            
                if (res.success) { 
                    setEnvio(Object.assign( envio, {'token':res.message}));

                    setEnvio(Object.assign( envio, values));
                        fetch (process.env.REACT_APP_RECLAMO_TARJETAS, {
                        method: 'POST',
                        headers: { 
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json' 
                        },
                        mode: 'cors',
                        body: JSON.stringify(envio)
                      })
                      .then(res => res.json())
                      .then(res => {
                        if (res.success) { // exito
                            captchaRef.current.reset(); 
                            window.location = `/enviado/reclamo` 
                        }else{
                            setNocaptcha(res.error)
                        }
                      });
                }else{
                    setNocaptcha('Por favor tilde la casilla de verificación para procesar el pedido')
                }
              });
        }


      const handleOnChange = (e) => {
        // console.log(`Seleccionaste ${e.target.value}`);
        // console.log(`Seleccionaste ${e.target.id}`);
        // console.log("Form::onChange", e);
        if(e.target.id === 'provincia'){setProvincia (e.target.value) };
      };  

    return(
        <Formik
        initialValues={{
          nombreApellido: "",
          doc: "",
          email: "",
          telefono: "",
          provincia: "",
          localidad: "",
          piso: "",
          domicilio: "",
          tarjeta: "",
          numero: "",
          operacion: "",
          entidad:"",
          comentario: ""
        }}
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        >
            {(formData) => (
                <Form onChange={handleOnChange}>
                            <Row className='mt-5'>
                            <Col md={12} className='mb-5'>
                                    <p>Si no podés operar con tu tarjeta te listamos una serie de posibilidades que pueden haber sucedido:<br/><br/>
                                    <ul>
                                        <li>Tu tarjeta se quedó sin saldo.</li>
                                        <li>Realizar múltiples intentos puede bloquear la tarjeta.</li>
                                        <li>El bloqueo de la tarjeta, generalmente proviene del banco emisor. PayFun no bloquea directamente la tarjeta.</li>
                                    </ul>
                                    Si ya has verificado que tu problema no es por ninguna de las posibilidades arriba mencionadas llena por favor el siguiente formulario para dar curso a su reclamo. Los items marcados con un asterisco (*) son obligatorios. Al terminar se le enviará un número de seguimiento, al mail declarado para que pueda llevar control del estado de su reclamo.</p>
                                </Col>
                                
                                <Col md={6} className='mb-4'>
                                    <label className="form-label" htmlFor='nombreApellido'>Nombres y apellido *</label>
                                    <Field id="nombreApellido" className='form-control' name="nombreApellido" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="nombreApellido"/>
                                </Col>
                                <Col md={6} className='mb-4'>
                                    <label className="form-label" htmlFor='doc'>DNI (sin puntos)*</label>
                                    <Field id="doc" className='form-control' name="doc" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="doc"/>
                                </Col>
                                <Col md={6} className='mb-4'>
                                    <label className="form-label" htmlFor='email'>Correo Electrónico *</label>
                                    <Field id="email" className='form-control' name="email" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="email"/>
                                </Col>
                                <Col md={6} className='mb-4'>
                                    <label className="form-label" htmlFor='telefono'>Teléfono celular *</label>
                                    <Field id="telefono" className='form-control' name="telefono" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="telefono"/>
                                </Col>
                                <Col md={12}>
                                    <Row>
                                        <Col md={4} className='mb-4'>
                                            <label className="form-label" htmlFor='provincia'>Provincia *</label>
                                            <Field as="select" className='form-select' name="provincia" id="provincia" autoComplete ='off'>
                                                <option  disable="true" value="">Seleccione una...</option>
                                                <option value="Buenos Aires">Buenos Aires</option>
                                                <option value="Ciudad Autónoma de Buenos Aires">Ciudad Autónoma de Buenos Aires</option>
                                                <option value="Catamarca">Catamarca</option>
                                                <option value="Chaco">Chaco</option>
                                                <option value="Chubut">Chubut</option>
                                                <option value="Córdoba">Córdoba</option>
                                                <option value="Corrientes">Corrientes</option>
                                                <option value="Entre Ríos">Entre Ríos</option>
                                                <option value="Formosa">Formosa</option>
                                                <option value="Jujuy">Jujuy</option>
                                                <option value="La Pampa">La Pampa</option>
                                                <option value="La Rioja">La Rioja</option>
                                                <option value="Mendoza">Mendoza</option>
                                                <option value="Misiones">Misiones</option>
                                                <option value="Neuquén">Neuquén</option>
                                                <option value="Río Negro">Río Negro</option>
                                                <option value="Salta">Salta</option>
                                                <option value="San Juan">San Juan</option>
                                                <option value="San Luis">San Luis</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Santa Fe">Santa Fe</option>
                                                <option value="Santiago del Estero">Santiago del Estero</option>
                                                <option value="Tierra del Fuego, Antártida e Islas del Atlántico Sur">Tierra del Fuego, Antártida e Islas del Atlántico Sur</option>
                                                <option value="Tucumán">Tucumán</option>
                                            </Field>
                                            <ErrorMessage component="div" className='errorMsj' name="provincia"/>
                                        </Col>
                                        <Col md={4} className='mb-4'>
                                            <label className="form-label" htmlFor='localidad'>Localidad *</label>
                                            <select id="localidad"  className="form-select" {...formData.getFieldProps('localidad')}>
                                                <option  disable="true" value="" style={{color:'gray'}}>Seleccione una provincia primero...</option>
                                                <Localidades provincia ={provincia}/>
                                            </select>
                                            <ErrorMessage component="div" className='errorMsj' name="localidad"/>
                                        </Col>
                                        <Col md={4} className='mb-4'>
                                            <label className="form-label" htmlFor='piso'>Piso / Dpto</label>
                                            <Field id="piso" className='form-control' name="piso" type="text" />
                                            <ErrorMessage component="div" className='errorMsj' name="piso"/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className='mb-4'>
                                    <label className="form-label" htmlFor='fiscal'>Domicilio (calle, numeración) *</label>
                                    <Field id="domicilio" className='form-control' name="domicilio" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="domicilio"/>
                                </Col>
                                <Col md={12} className='mb-4'>
                                    <Row>
                                        <Col md={3} className='mb-4'>
                                        <label className="form-label" htmlFor='tarjeta'>Nombre/Marca de la Tarjeta *</label>
                                            <Field as="select" className='form-select' name="tarjeta" id="tarjeta" autoComplete ='off'>
                                                <option  disable="true" value="">Seleccione una...</option>
                                                <option value="AMERICAN EXPRESS">AMERICAN EXPRESS</option>
                                                <option value="CABAL">CABAL</option>
                                                <option value="MAESTRO">MAESTRO</option>
                                                <option value="MASTERCARD">MASTERCARD</option>
                                                <option value="TARJETA NARANJA">TARJETA NARANJA</option>
                                                <option value="VISA">VISA</option>
                                            </Field>
                                            <ErrorMessage component="div" className='errorMsj' name="tarjeta"/>                                            
                                        </Col>
                                        <Col md={3} className='mb-4'>
                                            <label className="form-label" htmlFor='numero'>últimos 4 dígitos de su tarjeta *</label>
                                            <Field id="numero" className='form-control' name="numero" type="text" />
                                            <ErrorMessage component="div" className='errorMsj' name="numero"/>
                                        </Col>
                                        <Col md={3} className='mb-4'>
                                            <label className="form-label" htmlFor='numero'>Banco o entidad concedente *</label>
                                            <Field id="entidad" className='form-control' name="entidad" type="text" />
                                            <ErrorMessage component="div" className='errorMsj' name="entidad"/>
                                        </Col>
                                        <Col md={3} className='mb-4'>
                                            <label className="form-label" htmlFor='operacion'>Tipo de operación *</label>
                                            <select className='form-select' name="operacion" id="operacion" autoComplete ='off' {...formData.getFieldProps('operacion')}>
                                                <option  disable="true" value="">Seleccione una...</option>
                                                <option value="Débito">Débito</option>
                                                <option value="Crédito">Crédito</option>
                                            </select>
                                            <ErrorMessage component="div" className='errorMsj' name="operacion"/> 
                                        </Col>
                                    </Row>
                                </Col>
                               
                            <Col md={12}>
                                <label className="form-label mt-4" htmlFor='comentarios'>Detalle brevemente el inconveniente</label>
                                <br/>
                                <Field id="comentario" as='textarea' className='form-control' name="comentario" type="text" />
                                <ErrorMessage component="div" className='errorMsj' name="comentario"/>
                            </Col>    
                            <Col md={12} className='d-flex justify-content-center mt-4'>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
                            </Col>
                            <Col md={12} className='d-flex justify-content-center mb-3'>
                                <p><strong>{nocaptcha}</strong></p>
                            </Col> 
                            <Col md={12} className='d-flex justify-content-center mt-5 mb-5'>
                                <Row>
                                    <Col md={6} xs={6}>
                                    <button className="btn btn-primary" type="submit" style={{background:'#594184',border:'none'}}>Enviar</button>
                                    </Col>
                                    <Col md={6} xs={6}>
                                    <button className="btn btn-secondary" style={{background:'#ffbad5',border:'none'}} type="reset">Limpiar</button>
                                    </Col>
                                </Row>
                            
                            
                            </Col>
                            </Row>
                   
                </Form>
            )}
        </Formik>
    ) 
}