import{Button} from 'react-bootstrap';

export default function BotonSolicitud({positivo}){
     return(
          <Button 
          href='/integrate-con-payfun' 
          className={positivo ==='true' ? 'b-solicitud b-positivo':'b-solicitud b-negativo'}
          size='md'>
            Solicitá una prueba ahora
          </Button>
        )
}

