import {Col,Row} from 'react-bootstrap';
import Backgroundblock2 from '../img/solutions/block-2.jpg';



export default function Sublock({title,text}){
    
    return(
        <section id='sublock'>
        <div className='container-fluid sublock cyan-back' style={{backgroundImage:`url(${Backgroundblock2})`}}>
        <div className='container-body-2 container' >
            <Row>
                <Col md={12}  data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                    <h2 className='parrafo'>{title}</h2>
                </Col>
                <Col md={12}  data-aos="fade-up" data-aos-offset="200" data-aos-delay="700" data-aos-duration="1000">
                    <p className='parrafo'><strong>{text}</strong></p>
                </Col>
            </Row>
        </div>
        </div>
        </section>
    ) 
}