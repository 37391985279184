
import {Col,Row,Card} from 'react-bootstrap';
import{Nav} from 'react-bootstrap';


export default function MiniCard2 ({image,titulo,link}){
   return(
        <Card className='border-0 card shadow p-3 bg-body rounded' href='/contacto'>
                <Nav.Link href={link}>
                    <Card.Body>
                        <Row className='d-flex align-items-center text-center card-5-secundaria'>
                            <Col md={4}>
                            <img className='image-fluid' src={image} alt={image} width ='50px'/>
                            </Col>
                            <Col md={8}>
                            <p><strong>{titulo}</strong></p>
                            </Col>
                       </Row>
                    </Card.Body>
                </Nav.Link>
                </Card>
        )
}