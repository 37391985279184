import {Container,Col,Row} from 'react-bootstrap';
import BotonCustomLink from '../all/BotonCustomLink';

export default function Head(){
    return(
    <section id='headboard'>
        <div className="container-fluid integrateHeadboard">
            <Container>
                <Row>
                    <Col md={12} xs={12} className='texto text-center' >
                    <h1 className='integrateHeadboardColor'>Potenciá tu proceso de pago</h1><br/>
                            <p className='integrateHeadboardColor'>Nuestra pasarela de pago está diseñada para grandes empresas y entidades,<br/>para que tu recaudación sea fácil, rápida y segura</p>        
                    <br/>
                        <BotonCustomLink text='Solicitá una prueba ahora' link='#punto' tipo='white'/>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
    ) 
}