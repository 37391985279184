import { Col, Row } from "react-bootstrap"
import BotonCustom from "../../../components/all/BotonCustom";
import Lectora from "../../../components/img/photos/services/mano-con-lector.png";

const PlacaFinal = () =>{
    
    return(
        <div className="container-fluid placaFinal">
            <div className='container-body-2 neutral-div'>
                <Row>
                    <Col md={6} data-aos="fade-up"  data-aos-offset="200" data-aos-delay="100" data-aos-duration="1000">
                        <Row className='neutral-div'>
                            <Col md={12}>
                                <h2 className='parrafo'>{`Procesá el pago de\ndistintos productos\nen tu local físico`}</h2>
                            </Col>
                            <Col md={12} data-aos="fade-up"  data-aos-offset="200" data-aos-delay="200" data-aos-duration="1000" >
                            <BotonCustom text='Quiero gestionar pagos con código de barras' tipo='blue'/>
                            </Col>
                        </Row>
                     </Col>
                    <Col md={6}>
                    <img data-aos="fade-up" data-aos-offset="200" data-aos-delay="400" data-aos-duration="1000"className='img-fluid' src={Lectora} alt='imagen-placa'/>
                    </Col>

                </Row>
            </div>
        </div>
    )
}

export default PlacaFinal
