import {Container,Col,Row, Button} from 'react-bootstrap';
import tarjetas1 from '../img/tarjetas1.png'
import tarjetas2 from '../img/tarjetas2.png'


export default function Tarjetas({text}){
    return(
        <div className='containerFluid tarjetas'>
        <Container className='container-body-2 text-center'>
                <Row>
               <Col md={12} data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1000"><h3>Ofrecé todos los medios de pago</h3></Col>
                <Row className='tarjetas d-flex justify-content-center' data-aos="fade-up" data-aos-offset="200" data-aos-delay="200" data-aos-duration="1000">
                <Col md={4}><img className='img-fluid' src={tarjetas1} alt={tarjetas1} width='400'/></Col>
                <Col md={4}><img className='img-fluid' src={tarjetas2} alt={tarjetas2}  width='400'/></Col>
                </Row>
                <Col md={12} data-aos="fade-up" data-aos-offset="200"  data-aos-duration="1000"><br/>
                <Button href='/integrate-con-payfun' className='b-custom b-custom-pink'>{text}</Button>
                </Col>
                </Row>
            </Container>          
        </div>
    )

}