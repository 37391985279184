import BotonCustomLink from '../components/all/BotonCustomLink';
import Enviado from '../components/img/icons/sendForm.png';

export default function RespuestaLandingPage(){
  
    return(
    <div className='notFound' >
        <img width='130' height='130' src={Enviado} alt='enviado' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000"/>
        <h2 data-aos="fade-up" data-aos-offset="50" data-aos-delay="150" data-aos-duration="1000">¡Muchas gracias por interesarse en nuestros servicios!</h2>
        <p className='parrafo' data-aos="fade-up" data-aos-offset="250" data-aos-delay="50" data-aos-duration="1000">Nos contactaremos a la brevedad.</p>
        <div><BotonCustomLink text='Conocé más sobre PayFun' tipo='pink'/></div>
    </div>
    ) 
}