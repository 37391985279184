import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/venta-de-tickets-online.png'
import Sublock from '../../../components/solutions/Sublock'
import Tarjetas from '../../../components/solutions/Tarjetas'
import MultiCard from '../../../components/solutions/MultiCard'
import CustomCard from '../../../components/solutions/CustomCard'
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';
import { Container,Row,Col } from 'react-bootstrap'

const Tickets = () =>{
    return (
        <div id='solutions' className='containerFluid'>

        <Headboard title={`Venta de tickets\nonline para eventos`} image={HeadImage}/>
        
        <Sublock
        title = {`Gestioná el pago de tus tickets de manera fácil y segura`}
        text = {``}
        />

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                        <CustomCard title={`Integrá tu sistema de gestión\nactual con nuestros módulos`} text={`Disponemos de potentes y diversas API's\nque se integran fácilmente a tu plataforma.`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                        <CustomCard title={`Cobrá en tu propio sitio web\nintegralmente`} text={`Ofrecé un botón de pago en tu página web,\ningresando desde un celular o PC.`} align='left' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                        <CustomCard title={`¿No contás con una página web propia?`} text={`Nosotros nos encargamos de crear una landing con botón de pago para tus usuarios.`} align='center' button='off'/>
                    </Col>   
                </Row>
            </Container>
        </div>

        <MultiCard/>
        
        <Tarjetas text='Quiero optimizar mi recaudación'/>

        </div>

        
            )
}

export default Tickets