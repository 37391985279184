import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { useState } from "react";
import BotonCustomLink from "../components/all/BotonCustomLink";
import { Link } from "react-router-dom";

export default function Reclamos(){

    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    return(
        <div id='pre-reclamos' className='containerFluid'>
            <Container className='container-body-2'>
                <Row>
                    <Col md={12} className='text-center mb-2 mt-5'>
                        <h2>Centro de resoluciones</h2>
                    </Col>
                    <Col md={12} className='text-center mb-5 mt-3' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000">
                        <p>Entendemos que a veces pueden ocurrir <strong>transacciones que no reconozcas como propias</strong>, o quieras <strong>desconocer cargos</strong> por algún problema con la entrega de la contraprestación (compra de productos, suscripciones a servicios, o accesos a recursos) por lo cual pagaste online vía Payfun con tu tarjeta de crédito o débito.<br/>Si bien es posible que aún no hayas tenido ninguna, saber esto te ayudará a estar mejor preparado en caso de que ocurra.</p>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='d-grid gap-2 mb-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <Button className='contracargo-button' onClick={() => setItem1(!item1)} aria-controls="example-collapse-text" aria-expanded={item1} style={{textAlign:'left'}}>
                    {item1===true?'▲':'▼'} ¿Por qué mi pago o compra online no fue exitosa?
                    </Button>
                    <Collapse in={item1} className='contracargo-body'>
                        <div id="razones-reclamos1" >
                        Ocasionalmente, cuando un pago o compra online sale mal, las razones más comunes pueden ser:.<br/><br/>
                        <ul>
                            <li><strong>Artículo no recibido (ANR):</strong> El comprador no recibió un artículo, pedido o contraprestación de servicio esperada, luego de ser abonada online.</li>
                            <li><strong>Lo esperado es significativamente distinto o no concuerda con lo descripto por el vendedor o la empresa a quien se contrata el servicio (SDD):</strong> El comprador recibió un artículo, producto, servicio o contraprestación del pago, que es muy distintos a lo que se ofrecía originalmente o lo cual esperaba recibir.</li>
                            <li><strong>Transacciones no autorizadas (TNA):</strong> La compra o el pago se realizó sin el consentimiento del comprador o titular de la tarjeta.</li>
                         </ul>
                         <Link to='/reclamos/contracargos'><i>Más información</i></Link>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='d-grid gap-2 mb-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="150" data-aos-duration="1000">
                    <Button className='contracargo-button' onClick={() => setItem2(!item2)} aria-controls="example-collapse-text" aria-expanded={item2} style={{textAlign:'left'}}>
                    {item2===true?'▲':'▼'} ¿Qué hacer si tengo un problema en una transacción?
                    </Button>
                    <Collapse in={item2} className='contracargo-body'>
                        <div id="razones-reclamos2" >
                        Si el comprador tuviera que enfrentar alguno de estos problemas, podrá abrir en nuestra plataforma una disputa por transacción desconocida o rechazada:<br/><br/>
                        <ul>
                            <li><strong>Disputa o reclamo Payfun (DRP)</strong><br/>Disputa iniciada a través del Centro de Resoluciones Payfun (CRP) para negociar un resultado entre comprador/pagador y vendedor/prestador. Si no se resuelve, puede convertirse en un reclamo en el que Payfun investigue de oficio y proceda a determinar una resolución en base a la información o documentación del caso relevada.<br/><br/></li>
                            <li><strong>Contracargo de Tarjeta de Crédito (CTC)</strong><br/>El comprador/pagador inicia una disputa por una o más transacciones, a través de su Adquirente Emisor de la Tarjeta de crédito/débito, contra el Comercio, la empresa o entidad que generó el cargo en dicha tarjeta del usuario tarjeta habiente, sin pasar por Payfun.<br/><br/></li>
                            <li><strong>Rechazo Bancario (RB)</strong><br/>Disputa por transacción iniciada a través del Banco Emisor o autorizante de la transacción, solicitando una devolución o reintegro de fondos, por sospechas o reclamos de transacciones no autorizadas por el titular de la tarjeta. esto por lo general, se refiere a pagos con Tarjetas de Débito en la mayoría de los casos.</li>
                            
                            
                        </ul>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='d-grid gap-2  mb-5' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">
                    <Button className='contracargo-button' onClick={() => setItem3(!item3)} aria-controls="example-collapse-text" aria-expanded={item3} style={{textAlign:'left'}}>
                    {item3===true?'▲':'▼'} ¿Cuál es el rol de Payfun en una disputa?
                    </Button>
                    <Collapse in={item3} className='contracargo-body'>
                        <div id="razones-reclamos3" >
                        <ul>
                            <li>PayFun brinda un espacio de resolución de conflictos alternativo, objetivo, no excluyente de futuros reclamos administrativos y judiciales en contra de quién se tiene un conflicto.</li>
                            <li>El espacio de Disputas de PayFun es una herramienta que trata de que las partes se expresen con respeto, tratando de objetivar el reclamo, agilizar la solución, sin cargo extra en contra de quien lo realice, brindando las herramientas y recomendaciones a la otra parte para que no vuelva a ocurrir el inconveniente que generó la disputa.</li>
                            <li>PayFun no toma partido por ninguna de las partes; no juzga, no sentencia, simplemente intenta acercar a las partes para que resuelvan de manera cordial y rápida cualquier inconveniente.</li>
                            <li>Simplemente pone en conocimiento de las partes, lo establecido en los <Link to='/terminos-y-condiciones'><i>Términos y Condiciones de PayFun</i></Link>, respecto del caso concreto, lo que establece la ley de defensa del consumidor y demás legislaciones de fondo que rige la relación entre las partes del conflicto.</li>
                            <li>PayFun sólo busca que las partes que utilizan esta plataforma estén conformes y satisfechas, siempre dentro del marco legal que establecen la legislación y normativas que rigen este vínculo, la actividad que desarrollamos y los servicios que brindamos en Payfun.</li>
                            
                        </ul>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-1 text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="250" data-aos-duration="1000">
                        <Link to='/reclamos/contracargos'><p><strong>¿Qué es un contracargo?</strong></p></Link>
                    </Col>
                    <Col md={12}>
                        <Row className='botones mb-5 mt-5'>
                            <Col md={4} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="250" data-aos-duration="1000">
                                <BotonCustomLink text='Quiero dejar mi reclamo' link='/reclamos/formulario-de-reclamos' tipo='pink'/><br/><br/><br/>
                            </Col>
                            <Col md={4} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="250" data-aos-duration="1000">
                                <BotonCustomLink text='Seguimiento de mi reclamo' link='/reclamos/seguimiento-de-reclamos' tipo='white'/><br/><br/><br/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>       
        </div>
    ) 
}