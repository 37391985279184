import '../Services.css';
import HeadImage from '../../../components/img/photos/services/link-de-pago.png'
import Headboard from '../../../components/services/Headboard';
import { Col, Container, Row } from 'react-bootstrap';
import NumberCustomCard from '../../../components/services/NumberCustomCard';
import BotonCustom from '../../../components/all/BotonCustom';
import backgroundService from '../../../components/img/photos/services/background.png';
import CustomTitle from '../../../components/services/CustomTitle';
import ServiceIconCard from '../../../components/services/ServiceIconCard';
import Link from '../../../components/img/icons/i-link.png';
import LinkPersonalizado from '../../../components/img/icons/i-link-personalizado.png';


const LinkPagos = () =>{

    const itemsUno=[
        {texto:'Colocá el nombre de tu empresa o entidad.'},
        {texto:'Fijá precios o tarifas.'},
        {texto:'Definí diferentes métodos o canales de pago (tarjetas, efectivo, transferencias).'}
    ]
    const itemsDos=[
        {texto:'Ofrecé una forma de pago simple, rápida y segura.'},
        {texto:'Gestioná tu recaudación en pocos pasos y en minutos.'},
        {texto:'Controlá las cobranzas en tu panel en tiempo real.'}
    ]

    return (
        <div id='services' className='containerFluid'  style={{backgroundImage:`url(${backgroundService})`}}>

            <Headboard title={`Links de pago`} subtitle={`Cobrá online en solo minutos`} button={`Quiero mi link de pago`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>
                <Col md={12}>
                    <NumberCustomCard number='1' title={`Creá tus propios links de pago en simples pasos`} list={itemsUno}/><br/><br/>
                </Col>
                <Col md={12}>
                    <NumberCustomCard number='2' title={`Compartí tu link de pago y cobrá instantáneamente`} list={itemsDos}/>
                </Col>
                <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                    <BotonCustom text='Quiero mi link de pago' tipo='blue'/><br/><br/>
                </Col>
                <Col md ={12} data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <CustomTitle title={`Seleccioná el link de pago más adecuado para\ntu empresa o entidad`}/><br/><br/>
                </Col>
                <Col md ={12}>
                    <Row className="justify-content-between">    
                        <Col md ={5}>   
                            <ServiceIconCard title='Link de pago único' text={`Cobrá todo lo que necesites\ncentralizando los pagos en un\nsolo enlace.`} icon={Link}/>
                        </Col>
                        <Col md ={5}>   
                            <ServiceIconCard title='Link de pago personalizado' text={`Creá diferentes enlaces para\ncobrar cada uno de tus\nproductos/servicios.`} icon={LinkPersonalizado}/>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                    <BotonCustom text='Quiero mi link de pago' tipo='blue'/><br/><br/>
                </Col>

            </Row>
            </Container>
    </div>
    )
}

export default LinkPagos