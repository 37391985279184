import '../Services.css';
import HeadImage from '../../../components/img/photos/services/totem.png'
import Headboard from '../../../components/services/Headboard';
import ServiceCustomCard from '../../../components/services/ServiceCustomCard';
import CustomTitle from '../../../components/services/CustomTitle';
import TitleCard from '../../../components/services/TitleCard';
import NumberCustomCard from '../../../components/services/NumberCustomCard';
import { Row, Col, Container } from 'react-bootstrap';
import BotonCustom from '../../../components/all/BotonCustom';


const Totems = () =>{

    const itemsUno=[
        {texto:'Totems autogestionables físicos.'},
        {texto:'Sin necesidad de personal a cargo del dispositivo.'},
        {texto:'Recibí y visualizá pagos en tiempo real.'},
        {texto:'Disponible en todo momento 24x7x365.'},
    ]

    const itemsDos=[
        {bold:`Número de teléfono `,texto:`(se le envía un enlace).`},
        {bold:`Correo electrónico `,texto:`(se le envía un enlace).`},
        {bold:`Pago QR en Totem `,texto:`(escanéa con su dispositivo).`},
        {bold:`TOTEM `,texto:`(continúa el pago en el Totem).`}
    ]

    return (
        <div id='services' className='containerFluid'>

            <Headboard id='cabecera' name='cabecera' title={`Totems Interactivos`} subtitle={`Llevá tus pagos online al\nentorno presencial`} button={`Quiero mi Totem Autogestionable`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>
            <Col md={12}>       
            <ServiceCustomCard title={`Ahora tus clientes autogestionan sus pagos en tu tienda física`} list={itemsUno}/>
            </Col>
            <Col md ={12} data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
            <CustomTitle title={`¿Cómo funcionan los totems Autogestionables?`}/>
            </Col>
            <Col md={12}>
            <TitleCard title='tu cliente:'/> 
                <Row>
                    <Col md={6}><NumberCustomCard number='1' title={`Elige lo que\nnecesita pagar\nen ese momento`}/></Col>
                    <Col md={6}><NumberCustomCard number='2' title={`Ingresa con sus\ndatos`}/></Col>
                    <Col md={6}><NumberCustomCard number='3' title={`El sistema le\nmostrará sus\npagos pendientes`}/></Col>
                    <Col md={6}><NumberCustomCard number='4' title={`Selecciona\nlo que quiere\no puede pagar`}/></Col>
                    <Col md={12}><NumberCustomCard number='5' title={`Escoge cómo\ncontinuar el pago:`} list={itemsDos} mode={`col`}/></Col>
                </Row>
            </Col>    
            </Row>
            </Container>
            <div id='services' className='containerFluid white-div'>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>    
            <Col md ={12}>
            <ServiceCustomCard title={`Identificá desde dónde provienen tus pagos`} text={`Nuestro sistema discrimina todos tus puntos de cobro e informa cuántos\ningresos generaron cada uno de ellos, ya que podrás crear todos los\npuntos de venta (PDV) que quieras. Ideal para más de un local o centro\nde recaudación, así como un comercio con muchas sucursales.`}/><br/><br/>
            </Col>
            <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">
                <BotonCustom text='Quiero mi Totem Autogestionable' tipo='blue'/><br/><br/>
            </Col>
            </Row> 
            </Container>
            </div>
    </div>
    )
}

export default Totems