import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/cooperativas-de-servicios-2.png'
import Sublock from '../../../components/solutions/Sublock'
import { Col, Container, Row } from 'react-bootstrap'
import CustomCard from '../../../components/solutions/CustomCard'
import CustomList from '../../../components/solutions/CustomList'
import MultiCard from '../../../components/solutions/MultiCard'
import Tarjetas from '../../../components/solutions/Tarjetas'
import { Link } from 'react-router-dom'

const Cooperativas = () =>{
    const items=[
        {texto:'Obtené la información de tus cobranzas todos los días.'},
        {texto:'Recibí mails diarios para conocer tu recaudación.'},
        {texto:'Transformá las liquidaciones en información fácil de entender y procesar.'},
    ]
    return (
        <div id='solutions' className='containerFluid'>
        
        <Headboard title={`Cooperativas\nde Servicios`} image={HeadImage}/>
        
        <Sublock
        title = {`Facilitá la gestión a tus asociados`}
        text = {`Con PayFun simplificas el pago de facturas de tus usuarios.`}
        />

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                        <CustomCard title={`Concentrá la recaudación de\ntu cooperativa en un solo canal`} text={`Te ofrecemos una plataforma integral para\ntus cobros online con todos los medios de\npago.`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                        <CustomCard title={`Evolucioná tu cooperativa\ncon puestos de autogestión`} text={`Ofrecé a tus asociados un puesto de\nrecaudación interactiva y autogestionable\n(Totems) en el local físico que requieras.`} align='left' button='off'/>
                    </Col>
                </Row>
            </Container>
         </div>

         <CustomList title='Transparencia, facilidad y seguridad' subtitle='' text={items}/>

         <Container className='container-body-2 neutral-div'>
            <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
        </Container>

        <MultiCard/>
       
        <Tarjetas text='Quiero optimizar mi recaudación'/>

        </div>
            )
}

export default Cooperativas