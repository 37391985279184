import '../Services.css';
import HeadImage from '../../../components/img/photos/services/pago_qr.png'
import Headboard from '../../../components/services/Headboard';
import { Col, Container, Row } from 'react-bootstrap';
import ServiceCustomCard from '../../../components/services/ServiceCustomCard';
import BotonCustom from '../../../components/all/BotonCustom';
import CustomTitle from '../../../components/services/CustomTitle';
import NumberCustomCard from '../../../components/services/NumberCustomCard';


const PagosQr = () =>{
    const itemsUno=[
        {texto:'Definí un monto y generá el código.'},
        {texto:'Tus clientes lo escanean.'},
        {texto:'Recibí los pagos al instante.'}
    ]
    const itemsDos=[
        {texto:'Imprimí tu código QR.'},
        {texto:'Colócalo en tu local o establecimiento.'},
       
    ]


    return (
        <div id='services' className='containerFluid'>

            <Headboard title={`Pagos QR`} subtitle={`Cobrá más rápido y ágil con QR`} button={`Quiero mi código QR`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>   
            <Col md={12}>
                    <ServiceCustomCard number='1' title={`Creá tus propios códigos en el acto`} list={itemsUno}/><br/><br/>
                </Col>
                <Col md={12}>
                    <ServiceCustomCard number='2' title={`Descargá tu código QR y compartilo en tu tienda física`} list={itemsDos}/>
                </Col>
                <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                    <BotonCustom text='Quiero mi código QR' tipo='blue'/><br/><br/>
                </Col>
                <Col md ={12} data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <CustomTitle title={`¿Cómo pagan tus clientes?`}/>
                </Col>
                <Col md ={12}>
                <Row>
                    <Col md={6}><NumberCustomCard number='1' title={`Escanean tu\ncódigo QR`}/></Col>
                    <Col md={6}><NumberCustomCard number='2' title={`Seleccionan el\nmétodo de pago\nque desean`}/></Col>
                    <Col md={6}><NumberCustomCard number='3' title={`Ingresan el monto\na pagar`}/></Col>
                    <Col md={6}><NumberCustomCard number='4' title={`¡Pago exitoso!`}/></Col>
                </Row>
                </Col>
                <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                    <BotonCustom text='Quiero mi código QR' tipo='blue'/><br/><br/>
                </Col>
            </Row>
            </Container>    
        
            
            
        </div>
    )
}

export default PagosQr