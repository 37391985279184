import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/e-commerce.png'
import Sublock from '../../../components/solutions/Sublock'
import CustomList from '../../../components/solutions/CustomList'
import MultiCard from '../../../components/solutions/MultiCard'
import Tarjetas from '../../../components/solutions/Tarjetas'
import Commerce from '../../../components/solutions/Commerce'

const Ecommerce = () =>{

    const items=[
        {texto:'La pasarela de pago no funciona correctamente.'},
        {texto:'El medio de pago con el que desea abonar no está disponible.'},
        {texto:'Alta tasa de rechazos sin una causa válida.'},
    ]

    return (
       <div id='solutions' className='containerFluid'>

        <Headboard title={`E-commerce\ny Marketplace`} image={HeadImage}/>

        <Sublock
        title = {`¿Querés que tu E-commerce o\nMarketplace recaude más?`}
        text = {`PayFun es la pasarela de pago ideal para que aumentes la\nrecaudación de tu plataforma, ya sea E-commerce o\nMarketplace, ahorrando tiempo y dinero.`}
        />

        <CustomList title='Aumenta la cantidad de pagos capturados' subtitle='Las principales causas por las que tus clientes abandonan la compra son:' text={items}/>

        <MultiCard/>
       
        <Commerce/>

        <Tarjetas text='Quiero aumentar mi recaudación'/>

        </div>
            )
}

export default Ecommerce