import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/municipio2.png'
import Sublock from '../../../components/solutions/Sublock'
import MultiCard from '../../../components/solutions/MultiCard'
import Tarjetas from '../../../components/solutions/Tarjetas'
import CustomCard from '../../../components/solutions/CustomCard'
import { Col, Container, Row } from 'react-bootstrap'
import CustomList from '../../../components/solutions/CustomList'
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';

const Gobierno = () =>{

    const items=[
        {texto:'Obtené la información de tus cobranzas todos los días.'},
        {texto:'Recibí mails diarios para conocer tu recaudación.'},
        {texto:'Transformá las liquidaciones en información fácil de entender y procesar.'},
    ]

    return (
        <div id='solutions' className='containerFluid'>

        <Headboard title={`Gobiernos\ny municipios`} image={HeadImage}/>

        <Sublock
        title = {`Facilitá la gestión de tus contribuyentes`}
        text = {`Con PayFun simplificás el pago de facturas de tus usuarios.`}
        />

        <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                        <CustomCard title={`Concentrá la recaudación de tu\nentidad en un solo canal`} text={`Te ofrecemos una plataforma integral para\ntus cobros online con todos los medios de\npago.`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                        <CustomCard title={`Evolucioná tu entidad con\npuestos de autogestión`} text={`Ofrecé a tus asociados un puesto de\nrecaudación interactivo y autogestionable\n(Totems) en el local físico que requieras.`} align='left' button='off'/>
                    </Col>
                    <Col md={12}>    
                        <CustomCard title={`Integrá tu sistema de gestión actual con\nnuestros módulos de servicio`} text={`Disponemos de potentes y diversas API's que se integran\nfácilmente a tu plataforma.`} align='center' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <CustomList title='Transparencia, facilidad y seguridad' subtitle='' text={items} button='on' neutral='on'/>

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                    <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
                    </Col>
                </Row>
            </Container>
        </div>
        
        <MultiCard/>
        
        <Tarjetas text='Quiero optimizar mi recaudación'/>

        </div>
            )
}

export default Gobierno