import {Col,Row} from 'react-bootstrap';
import consorcios from '../../components/img/icons/i-consorcios.png';
import cooperativas from '../../components/img/icons/i-cooperativas.png';
import ecommerce from '../../components/img/icons/i-ecomerce.png';
import educacion from '../../components/img/icons/i-educacion.png';
import empresas from '../../components/img/icons/i-empresas.png';
import municipios from '../../components/img/icons/i-municipios.png';
import profesionales from '../../components/img/icons/i-profesionales.png';
import suscripciones from '../../components/img/icons/i-suscripciones.png';
import ticket from '../../components/img/icons/i-ticket.png';
import MiniCard2 from './MiniCard2';
import{Nav} from 'react-bootstrap';


export default function Seccion6b(){
    const lista =[
        {
          titulo:'E-commerce y Marketplace',
          image:ecommerce,
          link:'/soluciones/ecommerce-y-marketplace'
        },
        {
            titulo:'Suscripciones',
            image:suscripciones,
            link:'/soluciones/suscripciones'
        },
        {
            titulo:'Empresas y comercios',
            image:empresas,
            link:'/soluciones/empresas-y-comercios-fisicos'
        },
        {
          titulo:'Asociaciones profesionales',
          image:profesionales,
          link:'/soluciones/asociaciones-profesionales'
        },
        {
            titulo:'Instituciones educativas',
            image:educacion,
            link:'/soluciones/instituciones-educativas'
        },
        {
            titulo:'Consorcios',
            image:consorcios,
            link:'/soluciones/consorcios'
        },
        {
            titulo:'Cooperativas de servicios',
            image:cooperativas,
            link:'/soluciones/cooperativas-de-servicios'
        },
        {
            titulo:'Venta de tickets online',
            image:ticket,
            link:'/soluciones/venta-de-tickets-online-para-eventos'
        },
        {
            titulo:'Gobiernos y municipios',
            image:municipios,
            link:'/soluciones/gobiernos-y-municipios'
        }
        
      ];
    return(
        <div md={12}>
            <Row className='card-5-secundaria-container'>
                    <Nav>
                    {lista.map((e, index) =><Col md={4} xs={6} key={`cinco-${index}`}><MiniCard2 image={e.image} titulo={e.titulo} link={e.link} data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*200} data-aos-duration="500"/></Col>)}
                    </Nav>
            </Row>
        </div>
    ) 
}