import {Card, Col,Container,Row} from 'react-bootstrap';
import multicanal from '../../components/img/icons/i-multicanal.png';
import dinamico from '../../components/img/icons/i-dinamico.png';
import simple from '../../components/img/icons/i-simple.png';
import seguro from '../../components/img/icons/i-seguro.png';
import adaptable from '../../components/img/icons/i-adaptable.png';
import integral from '../../components/img/icons/i-integral.png';




export default function Items(){

    const items=[
        {
            titulo:'Multicanal',
            texto:'Unificá canales online y offline, con todos los medios de pago para tus clientes',
            image: multicanal
        },
        {
            titulo:'Dinámico',
            texto:'Contás con un equipo técnico para brindarte rápidas respuestas según tus necesidades.',
            image: dinamico
        },
        {
            titulo:'Simple',
            texto:'Recibí tu facturación de fácil lectura y entendimiento.',
            image: simple
        },
        {
            titulo:'Seguro',
            texto:'Información protegida, con altos estándares de seguridad.',
            image: seguro
        },
        {
            titulo:'Adaptable',
            texto:'Nos conectamos a tu empresa gracias a nuestras potentes APIs.',
            image: adaptable
        },
        {
            titulo:'Integral',
            texto:'Gestioná las cobranzas de tu empresa o entidad a través de nuestro panel de control.',
            image: integral
        },
    ]
    
    return(
        <section id='items' className='mt-5'>
            <Container className='container-body-2 d-flex justify-content-center'>
                
                <Row className="d-flex justify-content-center">
                    
                    <Col md={12}>
                        <div md={12}>
                            <Row className='card-4-secundaria-container'>
                            
                            {items.map((e, index) =>

                            <Col md={2} xs={6} key={`list-${index}`}>
                                <Card className='border-0 card' style={{minHeight:'260px'}}>
                                    <Card.Body className='card-4-secundaria cont-centrado'>
                                        <p><strong>{e.titulo}</strong></p>
                                        <p>{e.texto}</p>
                                        <div md={12} className='icon-div'>
                                        <img title={`atributos-${index}`} src={e.image} width="50px" alt={e.title}/>
                                        </div>


                                    </Card.Body>
                                </Card>


                            </Col>

                            )}



                            </Row>
                        </div>
                    </Col>
                </Row>
                </Container>
        </section>
    ) 
}