import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/instituciones-educativas.png'
import Sublock from '../../../components/solutions/Sublock'
import Tarjetas from '../../../components/solutions/Tarjetas'
import MultiCard from '../../../components/solutions/MultiCard'
import CustomCard from '../../../components/solutions/CustomCard'
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';
import { Container,Row,Col } from 'react-bootstrap'

const Educacion = () =>{
    return (
        <div id='solutions' className='containerFluid'>

        <Headboard title={`Instituciones\neducativas`} image={HeadImage}/>

        <Sublock
        title = {`Ahora cobrar cuotas es simple y seguro`}
        text = {`Integrá todos los pagos de tus alumnos en un mismo panel de recaudación.`}
        />

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                    <CustomCard title={`Para cada familia,\nuna solución`} text={`Ofrecé todas las alternativas de pago y\naumentá tu tasa de pagos capturados.`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                    <CustomCard title={`Gestioná la mora\neficazmente`} text={`PayFun te permite cobrar todas las cuotas,\naún cuando estén vencidas.`} align='left' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>


        <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                    <CustomCard title={`Cobrá en tu propio sitio web`} text={`Ofrecé un botón de pago en tu página web, ingresando\ndesde un celular o una PC.`} align='center' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                    <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
                    </Col>
                </Row>
            </Container>
        </div>
        
        <MultiCard/>
        
        <Tarjetas text='Quiero optimizar mi recaudación'/>




        </div>
            )
}

export default Educacion