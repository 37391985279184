import{Button} from 'react-bootstrap';

export default function QuieroSaberMas({link}){
     return(
          <Button 
          className='b-sabermas'
          href={link} 
          size='md'>
            Quiero saber más
          </Button>
        )
}

