import {useFormik} from 'formik';
import React, { useState, useRef} from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import * as Yup from 'yup';
import Localidades from '../components/all/Localidades';
import ReCAPTCHA from "react-google-recaptcha";
import Items from '../components/landingPage/Items';
import Servicios from '../components/landingPage/servicios';
import Head from '../components/landingPage/Head';



const LandingPage=()=>{

    const [provincia,setProvincia] = useState('');
    const[envio,setEnvio]=useState({});
    const[nocaptcha,setNocaptcha] = useState('');
    const cuitPat = '[0-9-]+$';
    const telPat = '[0-9-()+]+$';
    const captchaRef = useRef(null);

    

    const formik = useFormik({
        initialValues: {
            nombreApellido:'',
            email:'',
            cuit:'',
            telefono:'',
            provincia:'',
            localidad:'',
            empresa:'',
            servicio:[],
            observaciones:''
        },
        validationSchema: Yup.object({
            nombreApellido: Yup.string()
                .max(40, 'Hasta 40 caracteres')
                .required('Campo requerido'),
            email: Yup.string()
                .email('Email inválido')
                .required('Campo requerido'),
            cuit: Yup.string().matches(cuitPat, "Se requiere un CUIT válido")
                .min(11,'Se requiere un CUIT válido')
                .max(13,'Se requiere un CUIT válido')
                .required('Campo requerido'),
            telefono: Yup.string().matches(telPat, "Se requiere un teléfono válido")
                .min(10,'mínimo 10 dígitos')
                .max(20,'20 dígitos es los máximo')
                .required('Campo requerido'),
            provincia: Yup.string()
                .required('seleccione una provincia'),
            localidad: Yup.string()
                .required('seleccione una localidad'),
            empresa: Yup.string()
                .required('Coloque su tipo de empresa'),
            servicio: Yup.array().min(1,'Debe seleccionar al menos un servicio').of(Yup.string().required()).required(),
            observaciones: Yup.string()
                .max(260,'Ha excedido la cantidad de caracteres (260). Por favor sea breve.')
                .notRequired()
            
                               
          }),  
        onSubmit:values => {

            const codigoToken = {'token':captchaRef.current.getValue()}
            
            
            fetch (process.env.REACT_APP_GOOGLE, {
                method: 'POST',
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json' 
                },
                mode: 'cors',
                body: JSON.stringify(codigoToken)
              })
            .then(res => res.json())
            .then(res => {
                if (res.success) { 
                    setEnvio(Object.assign( envio, {'token':res.message}));

                    setEnvio(Object.assign( envio, values));
                    fetch (process.env.REACT_APP_INTEGRATE, {
                        method: 'POST',
                        headers: { 
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json' 
                        },
                        mode: 'cors',
                        body: JSON.stringify(envio)
                      })
                      .then(res => res.json())
                      .then(res => {
                        if (res.success) { // exito
                            captchaRef.current.reset();
                            formik.resetForm();  
                            window.location = `/gracias`
                        }else{
                            setNocaptcha(res.error)
                        }
                      });
                }else{
                    setNocaptcha('Por favor tilde la casilla de verificación para procesar el pedido')
                }
              });
     },
    });

    const handleOnChange = (e) => {
        // console.log(`Seleccionaste ${e.target.value}`);
        // console.log(`Seleccionaste ${e.target.id}`);
        // console.log("Form::onChange", e);
        if(e.target.id === 'provincia'){setProvincia (e.target.value) };
    }; 
    
   


    return(
        <>
        <Head/>
        <Container className='container-body-2'>  
        <Row>
        <Col md={12} style={{marginTop:'100px'}}><Servicios/></Col>    
        <Col md={12}><Items/></Col>
        <div id='punto'></div>
        <div id='integrate' className='containerFluid integrate'>
            <form onSubmit={formik.handleSubmit} onChange={handleOnChange}>
            
            <div className='container-body-2'>
                <Row>
                    <Col md={12} className='mb-2 text-center' style={{marginTop:'100px'}}>
                        <h2 className='textColor'>Integrate con PayFun</h2>
                    </Col>
                    <Col md={12} className='mb-2 mt-5 text-center'>
                        <p>Consultá por una prueba gratis y todo tipo de consulta acerca de nuestro servicio. Queremos conocer más sobre tu entidad o empresa. Por favor, completá los siguientes datos:</p>
                    </Col>
                    <Col md={12}  className='mt-5'>
                        <Row>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='nombreApellido'>Nombre y apellido completos</label>
                                <br/>
                                <input className="form-control" id='nombreApellido' type='text' {...formik.getFieldProps('nombreApellido')}/>
                                {formik.touched.nombreApellido && formik.errors.nombreApellido ? (<div className='errorMsj'>{formik.errors.nombreApellido}</div>) : null}
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='email'>Correo Electrónico</label>
                                <br/>
                                <input className="form-control" id='email' type='email' {...formik.getFieldProps('email')}/>
                                {formik.touched.email && formik.errors.email ? (<div className='errorMsj'>{formik.errors.email}</div>) : null}
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='cuit'>CUIT (ej: 20-41304093-1)</label>
                                <br/>
                                <input className="form-control" id='cuit' type='text' {...formik.getFieldProps('cuit')}/>
                                {formik.touched.cuit && formik.errors.cuit ? (<div className='errorMsj'>{formik.errors.cuit}</div>) : null}
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='telefono'>Teléfono</label>
                                <br/>
                                <input className="form-control" id='telefono' type='text' {...formik.getFieldProps('telefono')}/>
                                {formik.touched.telefono && formik.errors.telefono ? (<div className='errorMsj'>{formik.errors.telefono}</div>) : null}
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='provincia'>Provincia</label>
                                <select id="provincia" autoComplete ='off' className="form-select" {...formik.getFieldProps('provincia')}>
                                    <option  disable="true" value="">Seleccione una...</option>
                                    <option value="Buenos Aires">Buenos Aires</option>
                                    <option value="Ciudad Autónoma de Buenos Aires">Ciudad Autónoma de Buenos Aires</option>
                                    <option value="Catamarca">Catamarca</option>
                                    <option value="Chaco">Chaco</option>
                                    <option value="Chubut">Chubut</option>
                                    <option value="Córdoba">Córdoba</option>
                                    <option value="Corrientes">Corrientes</option>
                                    <option value="Entre Ríos">Entre Ríos</option>
                                    <option value="Formosa">Formosa</option>
                                    <option value="Jujuy">Jujuy</option>
                                    <option value="La Pampa">La Pampa</option>
                                    <option value="La Rioja">La Rioja</option>
                                    <option value="Mendoza">Mendoza</option>
                                    <option value="Misiones">Misiones</option>
                                    <option value="Neuquén">Neuquén</option>
                                    <option value="Río Negro">Río Negro</option>
                                    <option value="Salta">Salta</option>
                                    <option value="San Juan">San Juan</option>
                                    <option value="San Luis">San Luis</option>
                                    <option value="Santa Cruz">Santa Cruz</option>
                                    <option value="Santa Fe">Santa Fe</option>
                                    <option value="Santiago del Estero">Santiago del Estero</option>
                                    <option value="Tierra del Fuego, Antártida e Islas del Atlántico Sur">Tierra del Fuego, Antártida e Islas del Atlántico Sur</option>
                                    <option value="Tucumán">Tucumán</option>
                                </select>
                                {formik.touched.provincia && formik.errors.provincia ? (<div className='errorMsj'>{formik.errors.provincia}</div>) : null}
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='localidad'>Localidad</label>
                                <select id="localidad"  className="form-select" {...formik.getFieldProps('localidad')}>
                                    <option  disable="true" value="" style={{color:'gray'}}>Seleccione una provincia primero...</option>
                                    <Localidades provincia ={provincia}/>
                                </select>
                                {formik.touched.localidad && formik.errors.localidad ? (<div className='errorMsj'>{formik.errors.localidad}</div>) : null}    
                            </Col>
                            <Col md={6} className='mb-4'>
                                <label className="form-label" htmlFor='empresa'>Tipo de empresa o entidad</label>
                                <select id="empresa"  className="form-select" {...formik.getFieldProps('empresa')}>
                                    <option  disable="true" value="">Seleccione una...</option>
                                    <option value="Profesional">Profesional</option>
                                    <option value="Consorcio">Consorcio</option>
                                    <option value="Cooperativa">Cooperativa</option>
                                    <option value="E-commerce">E-commerce</option>
                                    <option value="Institución educativa">Institución educativa</option>
                                    <option value="Empresa privada">Empresa privada</option>
                                    <option value="Comercio">Comercio</option>
                                    <option value="Gobierno y/o municipio">Gobiernos y municipios</option>
                                    <option value="Servicio de Suscripciones">Suscripciones</option>
                                    <option value="Venta de tickets">Venta de tickets</option>
                                </select>
                                {formik.touched.empresa && formik.errors.empresa ? (<div className='errorMsj'>{formik.errors.empresa}</div>) : null}
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col md={6}>
                                <label className="form-label" htmlFor='empresa'>¿En qué servicio está interesado?</label>    
                                <div className="form-check" role="group" aria-labelledby="checkbox-group"  {...formik.getFieldProps('servicio')}>    
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Código de Barras"/>Código de Barras</label><br/>
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Link de pago"/>Link de Pago</label><br/>
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Órdenes de pago"/>Órdenes de pago</label><br/>
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Pagos con QR"/>Pagos con QR</label><br/>
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Pagos en sitio web"/>Pagos en sitio web</label><br/>
                                    <label><input className="form-check-input" type="checkbox" name="servicio" value="Totems interactivos"/>Totems Interactivos</label><br/>   
                                </div>
                                {formik.touched.servicio && formik.errors.servicio ? (<div className='errorMsj'>{formik.errors.servicio}</div>) : null}   
                            </Col>
                    <Col md={6}>
                            <label className="form-label" htmlFor='observaciones'>Información adicional</label>
                            <br/>
                            <textarea rows='5' className="form-control" id='observaciones' type='text' {...formik.getFieldProps('observaciones')}/>
                            {formik.touched.observaciones && formik.errors.observaciones ? (<div className='errorMsj'>{formik.errors.observaciones}</div>) : null}
                        </Col>
                    
                    <Col md={12} className='d-flex justify-content-center mt-4'>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
                    </Col>
                    <Col md={12} className='d-flex justify-content-center mb-3'>
                        <p><strong>{nocaptcha}</strong></p>
                    </Col>
                    <Col md={12} className='d-flex justify-content-center'><button className="btn btn-primary mt-4 mb-5" type='submit'>Enviar</button></Col>
                </Row>    
            </div>
               
        </form>
        </div> 
        </Row>
        </Container>
        </>
    )
}

export default LandingPage