import check from '../../components/img/photos/check.png';
import {Container,Col,Row} from 'react-bootstrap';
import BotonSolicitud from '../all/BotonSolicitud';

export default function CustomList({title,subtitle,text,button,neutral}){
    return(
        <div className={neutral==='on'?'containerFluid neutral-div':'containerFluid white-div'}>
            <Container className='container-body-2 custom-list'>
                <Row>
                <Col md={10} className='mx-auto' data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000"><h2>{title}</h2></Col>
                {subtitle !== null &&
                <Col md={10} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay="200" data-aos-duration="1000"><p>{subtitle}</p><br/></Col>
                }
                
                {text.map((e, index) =>
                <Col md={10}  key={`list-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> {e.texto}</p></Col>
                )}

                {button === 'on' && 
                <Col md={10} className='mx-auto' style={{paddingLeft:'30px'}} data-aos="fade-up" data-aos-offset="200" data-aos-delay="600" data-aos-duration="1000"><br/><BotonSolicitud positivo ='true'/></Col>
                
                }

                </Row>
            </Container>
        </div>            
         
    )

}