import { useEffect } from "react";
import { useState } from "react"

const useTemas = (subtema) =>{
    const temas =[
        {
            codigo:1,
            concepto:'Tarjetas de crédito y débito',
            contenido:[
                {
                    subcodigo:103,
                    subtema:'Tarjeta bloqueada o inhabilitada / No puedo operar con mi tarjeta',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:106,
                    subtema:'Intereses no procedentes o mal aplicados',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:107,
                    subtema:'Cargos / comisiones no procedentes o mal aplicados',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:108,
                    subtema:'Promociones / descuentos / programas de beneficios no aplicados o aplicados incorrectamente',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:109,
                    subtema:'Desconocimiento de compras o transacciones',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:199,
                    subtema:'Otros',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                }
            ]
        },
        {
            codigo:2,
            concepto:'Cuentas virtuales',
            contenido:[
                {
                    subcodigo:202,
                    subtema:'Intereses mal aplicados',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:203,
                    subtema:'Cargos / comisiones no procedentes o mal aplicados',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:204,
                    subtema:'Promociones / descuentos / programas de beneficios no aplicados o aplicados incorrectamente',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:205,
                    subtema:'Débitos / monto de débitos no consentidos o pactados',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:206,
                    subtema:'Solicitud de baja de cuenta no tramitada',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:207,
                    subtema:'Aplicación de condiciones no pactadas',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:208,
                    subtema:'Cierre de cuenta dispuesto por la entidad',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:209,
                    subtema:'Producto / servicio no solicitado',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:299,
                    subtema:'Otros',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                }
            ]
        },
        {
            codigo:6,
            concepto:'Información, trato discriminatorio y mala atención',
            contenido:[
                {
                    subcodigo:604,
                    subtema:'Información errónea, sesgada o incompleta sobre condiciones de productos y servicios',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:605,
                    subtema:'Publicidad engañosa',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:699,
                    subtema:'Otros',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                }
            ]
        },
        {
            codigo:8,
            concepto:'Gestión cobranza',
            contenido:[
                {
                    subcodigo:801,
                    subtema:'Falta de respuesta al requerimiento de estados de cuenta o libre deuda',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:802,
                    subtema:'Trato indigno por terceros a cargo de las gestiones de cobro',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:899,
                    subtema:'Otros',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                }
            ]
        },
        {
            codigo:99,
            concepto:'Otros',
            contenido:[
                {
                    subcodigo:9901,
                    subtema:'Retenciones y percepciones impositivas cuestionadas',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                },
                {
                    subcodigo:9999,
                    subtema:'Otros',
                    descripcion:'',
                    link:'/reclamos/formulario/'
                }
            ]
        },
    ]

    const [data,setData] = useState([])

    useEffect(() => {
        if(subtema == null){setData (temas)}
        else{
            const tema = parseInt(subtema.slice(0,-2))
           for (let index = 0; index < temas.length; index++) {
               if(temas[index].codigo === tema) {
                // setData ({codigo:temas[index].codigo,concepto:temas[index].concepto})

            for (let subindex = 0; subindex < temas[index].contenido.length;subindex++){
                if(temas[index].contenido[subindex].subcodigo === parseInt(subtema)){
                    setData ({codigo:temas[index].codigo,concepto:temas[index].concepto,subcodigo:temas[index].contenido[subindex].subcodigo,subtema:temas[index].contenido[subindex].subtema,descripcion:temas[index].contenido[subindex].descripcion,link:temas[index].contenido[subindex].link})
                }
            }    
            
            
            }
              } 
            
            

        }

    }, [setData]);
    
    

    return data
}

export default useTemas