import{Button} from 'react-bootstrap';

export default function BotonCustomLink({text,link='/',tipo ='pink'}){

  const colorButton =()=>{
  switch(tipo){
    case 'blue': return 'b-custom b-custom-blue';
    case 'pink': return 'b-custom b-custom-pink';
    default : return 'b-custom b-custom-white';
  }
  }
     return(
          <Button 
          href={link} 
          className= {colorButton()}
          size='md'
          >
            {text}
          </Button>
        )
}

