import {Card,Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import check from '../../components/img/photos/check.png';

export default function NumberCustomCard({number='0',title,list='', mode=''}){
    
   return(
        
         <Card className='border-0 numberCustomCard' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                <Card.Body>
                <Row className='align-items-center'>

                <Col md={2} xs={2}>        
                <Card className='border-0 numberCard text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000">
                <Card.Body className='contentCard'>
                <h1>{number}</h1>
                </Card.Body>
                </Card>
                </Col>

                <Col md={10} xs={10}>
                    {list===''?
                        <Row>
                        <Col md={12} xs={12}className='align-self-center'>
                            <h3 className='parrafo' data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="1000">{title}</h3>
                        </Col>
                        </Row>
                    :mode==='col'?
                        <Row>
                        <Col md={4} xs={12} className='align-self-center'>
                            <h3 className='parrafo' data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="1000">{title}</h3><br/>
                        </Col>
                        <Col md={8} xs={12}>
                        <Row>
                            {list.map((e, index) =>
                            e.bold === 'Pago QR en Totem '?
                                <Col md={12}  key={`${list}-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> <span style={{fontWeight:'900'}}><Link to='/servicios/pagos-con-qr'>Pago QR</Link> en Totem </span>{e.texto}</p></Col>
                                :
                                <Col md={12}  key={`${list}-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> <span style={{fontWeight:'900'}}>{e.bold}</span>{e.texto}</p></Col>
                                )}
                        </Row>
                        </Col>
                        </Row>
                    :
                        <Row className='align-self-center'>
                        <Col md={12} xs={12} >
                            <h3 className='parrafo' data-aos="fade-up" data-aos-offset="50" data-aos-delay="300" data-aos-duration="1000">{title}</h3><br/>
                        </Col>
                        <Col md={12} xs={12}>
                        <Row>
                            {list.map((e, index) =>
                            <Col md={12}  key={`${list}-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> <span style={{fontWeight:'900'}}>{e.bold}</span>{e.texto}</p></Col>)}
                        </Row>
                        </Col>
                        </Row>
                        
                    
                    }
                </Col>

                </Row>       
                </Card.Body>
            </Card>
    ) 
}