import {Container,Col,Row} from 'react-bootstrap';
import Background from '../img/photos/solutions/head-background-solutions.png'
import BotonSolicitud from '../../components/all/BotonSolicitud';

export default function Headboard({title,image}){
    return(
    <section id='headboard'>
        <div className="container-fluid headboard" style={{backgroundImage:`url(${Background})`}}>
            <Container>
                <Row>
                    <Col md={7} xs={12} className='texto'  data-aos="fade-right" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                        <h1 className="parrafo">{title}</h1><br/>
                        <BotonSolicitud positivo='true'/>
                    </Col>
                    <Col md={5} xs={12}  data-aos="fade-left" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                    <img style={{'marginTop':'30px'}} className='img-fluid' src={image} alt='cabecera-inicio'/>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
    ) 
}