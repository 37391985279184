import TarjetasOperaciones from "./forms/Tarjetas1"
import Tarjetas from "./forms/Tarjetas2"
import CuentasOperaciones from "./forms/Cuentas1"
import Cuentas from "./forms/Cuentas2"
import Simple from "./forms/Simple"


const ImportarFormulario=({tema})=>{

    const contenido = tema;
    
    const project = () => {
        switch(tema.subcodigo) {
      
            case 103:   return <Tarjetas data={contenido}/>;
            case 199:   return <Tarjetas data={contenido}/>;
            case 106:   return <TarjetasOperaciones data={contenido}/>;
            case 107:   return <TarjetasOperaciones data={contenido}/>;
            case 108:   return <TarjetasOperaciones data={contenido}/>;
            case 109:   return <TarjetasOperaciones data={contenido}/>;

            case 206:  return <Cuentas data={contenido}/>;
            case 207:  return <Cuentas data={contenido}/>;
            case 208:  return <Cuentas data={contenido}/>;
            case 209:  return <Cuentas data={contenido}/>;
            case 299:  return <Cuentas data={contenido}/>;
            case 202: return <CuentasOperaciones data={contenido}/>;
            case 203: return <CuentasOperaciones data={contenido}/>;
            case 204: return <CuentasOperaciones data={contenido}/>;
            case 205: return <CuentasOperaciones data={contenido}/>;
            
            default:      return <Simple data={contenido}/>;
         }
    }
      
        return (
        <div>{ project() }</div>
        )
}

export default ImportarFormulario