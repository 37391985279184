import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
  } from "@react-pdf/renderer";
  import logo from "../img/logo-payfun.png"
  // Create styles
  // Register font

  const styles = StyleSheet.create({
    
    image: {
        width: 100,
        height: 26,
        marginLeft:30,
        marginTop: 30,
      },
    page: {
      backgroundColor: "#fff",
      color: "#21005c",
      padding: 40,
    },
    section: {
      margin: 40,
      padding: 40,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });
  
  // Create Document Component
  function PdfViewer({texto}) {
    const fecha = `Términos y condiciones (validos al ${texto.substring(0,10)})`;
    return (
      <PDFViewer style={styles.viewer}>
        {/* Start of the document*/}
        <Document title={fecha}>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
          <Image
            style={styles.image}
            src={logo}
            /> 
            <View style={{fontSize:'20px',textAlign:'center',marginTop:'50px'}}>
              <Text>Términos y Condiciones</Text>
            </View>
            <View style={{fontSize:'8px',textAlign:'center',marginTop:'5px'}}>
              <Text>{fecha}</Text>
            </View>
            <View style={{fontSize:'10px',textAlign:'left',margin:'20px 40px 70px 40px'}}>
              <Text>{texto.slice(10)}</Text>
            </View>
            <View fixed style={{fontSize:'7px',fontStyle:'italic',textAlign:'center',bottom:'-20px'}}>
              <Text render={({ pageNumber, totalPages }) => (`- PayFun. ${fecha} / Pag. Nº${pageNumber} -`)} fixed />
            </View>
          </Page>
        </Document>
        
      </PDFViewer>
    );
  }
  export default PdfViewer;