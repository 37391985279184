import {Container,Col,Row} from 'react-bootstrap';
import BotonCustom from '../all/BotonCustom';

export default function Headboard({title,subtitle,button,image}){
    return(
    <section id='headboard'>
        <div className="container-fluid headboard">
            <Container>
                <Row>
                    <Col md={7} xs={12} className='texto'  data-aos="fade-right" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                        <h1 className="parrafo">{title}</h1>
                        <h3 className="parrafo">{subtitle}</h3><br/>
                        <BotonCustom text={button} tipo='white'/>
                    </Col>
                    <Col md={5} xs={12} className='d-flex justify-content-center' data-aos="fade" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                        <div className='headCircle'>    
                            <img data-aos="fade" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000"className='img-fluid' src={image} alt='cabecera-inicio'/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
    ) 
}