import{Nav,Navbar,Container,NavDropdown} from 'react-bootstrap';
import logo from '../../components/img/logo-payfun.png';
import BotonSolicitud from './BotonSolicitud';

const Head = () =>{
    return(
        <>
        <Navbar className='navegacion' collapseOnSelect  fixed="top" expand='sm' bg="light" variant="light">
            <Container>
            <Navbar.Brand href='/'>
            <img src={logo} alt='Payfun' width='180' height='auto'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='navbarScroll' data-bs-target='#navbarScroll'/>
            <Navbar.Collapse id='navbarScroll' className="justify-content-end">
                <Nav>
                    <Nav.Item><Nav.Link eventKey='1' href='/'>Inicio</Nav.Link></Nav.Item>
                    <NavDropdown title="Soluciones" id="basic-nav-dropdown">
                        <NavDropdown.Item href='/soluciones/asociaciones-profesionales'>Profesionales</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/consorcios'>Consorcios</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/cooperativas-de-servicios'>Cooperativas</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/ecommerce-y-marketplace'>E-commerce</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/instituciones-educativas'>Instituciones educativas</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/empresas-y-comercios-fisicos'>Empresas y comercios</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/gobiernos-y-municipios'>Gobiernos y municipios</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/suscripciones'>Suscripciones</NavDropdown.Item>
                        <NavDropdown.Item href='/soluciones/venta-de-tickets-online-para-eventos'>Venta de tickets</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Servicios" id="basic-nav-dropdown">
                        <NavDropdown.Item href='/servicios/lector-de-codigo-de-barras'>Código de Barras</NavDropdown.Item>
                        <NavDropdown.Item href='/servicios/links-de-pago'>Link de Pago</NavDropdown.Item>
                        <NavDropdown.Item href='/servicios/ordenes-de-pago'>Órdenes de Pago</NavDropdown.Item>
                        <NavDropdown.Item href='/servicios/pagos-con-qr'>Pagos con QR</NavDropdown.Item>
                        <NavDropdown.Item href='/servicios/pagos-en-tu-sitio-web'>Pagos en Sitio Web</NavDropdown.Item>
                        <NavDropdown.Item href='/servicios/totems-interactivos'>Totems Interactivos</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Legales" id="basic-nav-dropdown">
                        <NavDropdown.Item href='/terminos-y-condiciones'>Términos y Condiciones</NavDropdown.Item>   
                    </NavDropdown>
                    <Nav.Item><Nav.Link eventKey='2' href='/preguntas-frecuentes'>FAQ</Nav.Link></Nav.Item>
                    <NavDropdown style={{'marginRight':'20px'}} title="Contacto" id="basic-nav-dropdown">
                        <NavDropdown.Item href='/integrate-con-payfun'>Integrate con PayFun</NavDropdown.Item>
                        <NavDropdown.Item href='/reclamos/centro-de-consultas-y-reclamos'>Reclamos</NavDropdown.Item>
                        <NavDropdown.Item href='https://prealtas.payfun.com.ar'>Prealtas</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item><BotonSolicitud positivo='true'/></Nav.Item> 
                </Nav>    
            </Navbar.Collapse>    
                
            </Container>
        </Navbar>
        
        </>
    );
}

export default Head;