import {Container,Col,Row,Card} from 'react-bootstrap';
import Seccion6b from './Seccion6b'
import seccion5FondoCabecera from '../../components/img/photos/seccion5-cabecera-background.png'



export default function Seccion6(){
    return(
        <section id='inicio5'>
            <div className='container-fluid cyan-back seccion-centrado' style={{backgroundImage:`url(${seccion5FondoCabecera})`}}>
                <Container className='container-body-2'>
                    <Row className='d-flex justify-content-center'>
                        <Col md={12}>
                            
                                <Card md={12} className='shadow p-3 mb-5 bg-body rounded text-center border-0'>
                                    <Card.Body className='card-5-principal' data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <h3>Soluciones enfocadas para</h3>
                                    <br/>
                                    <Seccion6b/>
                                    </Card.Body>
                                </Card>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    ) 
}