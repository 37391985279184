import { useParams } from "react-router-dom"
import useTemas from "../../hooks/useTemas"
import { Col, Container, Row } from "react-bootstrap"
import ImportarFormulario from "./ImportarFormulario"


const Formulario = () => {

    const {subtema} = useParams()
    const tema = useTemas(subtema)
    
    return(
        <>
    {tema.concepto!==undefined?
        <Container className='container-body-2' style={{padding: '50px 0px 60px 0px'}}>
        <Row>
            <Col md={12} className='disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                <a href='/reclamos/centro-de-consultas-y-reclamos' target='_self'><p><strong>{`<< Volver al Centro de Consultas y Reclamos`}</strong></p></a>
            </Col>
            <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="100"  data-aos-duration="1000">    
                <h2>{`${tema.concepto}`}</h2>
                <h4>{`${tema.subtema}`}</h4>
            </Col>     
        </Row>
        
        <ImportarFormulario tema={tema}/>
        </Container>
    
    :
    <Container className='container-body-2' style={{padding: '50px 0px 60px 0px', minHeight: '550px'}}>
        <Row>
            <Col md={12} className='disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                <a href='/reclamos/centro-de-consultas-y-reclamos' target='_self'><p><strong>{`<< Volver al Centro de Consultas y Reclamos`}</strong></p></a>
            </Col>
            <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
                <h2>Ese tipo de reclamo no existe</h2>
            </Col>
            <Col md={12} className='text-center mt-3 disputas' data-aos="fade-up" data-aos-offset="50"  data-aos-duration="1000">    
            <h4>Vuelva a la página de selección y asegurese de haber indicado bien el tipo de reclamo a hacer.</h4>
            </Col>     
        </Row>
    </Container>
    }
    </>
    )
}

export default Formulario