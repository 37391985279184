import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/empresas-y-comercios.png'
import Sublock from '../../../components/solutions/Sublock'
import MultiCard from '../../../components/solutions/MultiCard'
import Tarjetas from '../../../components/solutions/Tarjetas'
import CustomCard from '../../../components/solutions/CustomCard'
import { Col, Container, Row } from 'react-bootstrap'

const Empresas = () =>{
    return (
        <div id='solutions' className='containerFluid'>
        
        <Headboard title={`Empresas\ny comercios físicos`} image={HeadImage}/>
        
        <Sublock
        title = {`¿Querés aumentar la recaudación de tu empresa?`}
        text = {`Cobrá más evitando los rechazos de pago con tarjetas.`}
        />

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                    <CustomCard title={`Ahorrá dinero y recauda\nmás con PayFun`} text={`Cobrando con QR y sin necesidad de utilizar\ndispositivos físicos (Posnet,  Lapos, etc).`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                    <CustomCard title={`¿Contás con varias\nsucursales?`} text={`Creá y controlá todos los puntos de venta\nen tiempo real y en un mismo panel.`} align='left' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <MultiCard/>

        <Tarjetas text='Quiero aumentar mi recaudación'/>

        </div>
            )
}

export default Empresas