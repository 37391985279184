import '../Services.css';
import HeadImage from '../../../components/img/photos/services/orden-de-pago.png'
import Headboard from '../../../components/services/Headboard';
import ServiceIconCard from '../../../components/services/ServiceIconCard';
import Producto from '../../../components/img/icons/i-producto.png';
import Productos from '../../../components/img/icons/i-productos.png';
import backgroundService from '../../../components/img/photos/services/background.png';
import ServiceCustomCard from '../../../components/services/ServiceCustomCard';
import { Col, Container, Row } from 'react-bootstrap';
import CustomTitle from '../../../components/services/CustomTitle';
import BotonCustom from '../../../components/all/BotonCustom';


const OrdenesPagos = () =>{
    return (
        <div id='services' className='containerFluid' style={{backgroundImage:`url(${backgroundService})`}}>

            <Headboard title={`Órdenes de pago\nsimples / múltiples`} subtitle={`Mejorá la gestión de tus órdenes de pago`} button={`Quiero crear mis órdenes de pago`} image={HeadImage}/>
            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
            <Row>   
                <Col md ={12}>
                    <ServiceCustomCard title={`Creá automáticamente órdenes de pago`} text={`Luego de realizado el pago, tu cliente recibe de forma automática\nun comprobante por correo electrónico.`} boton={`Quiero crear mis órdenes de pago`}/><br/><br/>
                </Col> 
                <Col md ={12} data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                    <CustomTitle title={`Nos adaptamos a tus necesidades`}/><br/><br/>
                </Col>
                <Col md ={12}>
                    <Row className="justify-content-between">    
                        <Col md ={5}>   
                            <ServiceIconCard title='Simple' text={`Generá un comprobante de pago\npara un producto/servicio en\nespecífico.`} icon={Producto}/>
                        </Col>
                        <Col md ={5}>   
                            <ServiceIconCard title='Múltiples' text={`Generá un comprobante de pago\npara dos o más\nproductos/servicios a la vez.`} icon={Productos}/>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className='text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="200" data-aos-duration="1000"><br/><br/>
                    <BotonCustom text='Quiero crear mis órdenes de pago' tipo='blue'/><br/><br/>
                </Col>
            </Row> 



            </Container>
        </div>
    )
}

export default OrdenesPagos