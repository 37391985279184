import { useState } from 'react';
import { Col, Button,Collapse,OverlayTrigger, Tooltip} from 'react-bootstrap';


const Items=({tema})=>{

    const [item,setItem] = useState(false)
    return(

        <Col md={12} className='mt-2 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000">
                    <Button className='disputas-button' onClick={() =>setItem(!item)} aria-controls="example-collapse-text" aria-expanded={item} style={{textAlign:'left'}}>
                    
                    {item===true?'▲':'▼'} {tema.concepto}
                    </Button>
                    <Collapse in={item} className='disputas-body'>
                        <div id={`reclamo-${tema.codigo}`} >
                        <ul>
                            {tema.contenido.map((cont,index)=>
                                <li key={cont.subcodigo}>
                                <OverlayTrigger placement={'auto'} overlay={<Tooltip>Click aqui para reclamar</Tooltip>}>
                                    <a href={`${cont.link}${cont.subcodigo}`} target='_self'><p>{cont.subtema}</p></a>
                                </OverlayTrigger>   
                                </li>
                            )}
                        </ul>
                        
                        </div>
                    </Collapse>
                </Col>
    )


}

export default Items