import {Card,Col,Row} from 'react-bootstrap';
import check from '../../components/img/photos/check.png';
import BotonCustom from '../all/BotonCustom';
import woocommerce from '../img/photos/woocommerce.png'
import oscommerce from '../img/photos/oscommerce.png'
import magento from '../img/photos/magento.png'
import odoo from '../img/photos/odoo.png'
import prestashop from '../img/photos/prestashop.png'
import shopware from '../img/photos/shopware.png'


export default function ServiceCustomCard({title,text='',list='',boton='',ecommerce=''}){
    return(
            <Card className='border-0 customCard '  data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000">
                <Card.Body>
                    <div  data-aos="fade-up" data-aos-offset="200" data-aos-delay="100" data-aos-duration="1000">
                    <h3 className="parrafo text-center">{title}</h3> 
                    </div><br/>   
                    <Row>   
                   {text!==''?
                        <Col md={12}  className='text-center' data-aos="fade-up" data-aos-offset="200" data-aos-delay="200" data-aos-duration="1000">
                        <p className="parrafo texto">{text}</p>
                        </Col>
                        :
                        list.map((e, index) =>
                        <Col md={9}  key={`${list}-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> {e.texto}</p></Col>)
                    }
                    {ecommerce === 'on' && 
                    <Col md={12} className="text-center" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                      <Card className='border-0' data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                        <Card.Body>
                        <Row className='d-flex justify-content-center align-items-center'  data-aos="fade-up" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={woocommerce} alt={woocommerce}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={magento} alt={magento}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={prestashop} alt={prestashop}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={oscommerce} alt={oscommerce}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={odoo} alt={odoo}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={shopware} alt={shopware}/></Col>
                        </Row>
                    </Card.Body>
                </Card>  
                    </Col>
                    }
                    {boton !== '' && <Col md={12} className="text-center" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000"><BotonCustom text={boton} tipo='blue'/></Col>}   
                    </Row>        
                </Card.Body>
            </Card>        
    ) 
}