import '../Solutions.css';
import HeadImage from '../../../components/img/photos/solutions/asociaciones-profesionales.png'
import Sublock from '../../../components/solutions/Sublock';
import Headboard from '../../../components/solutions/Headboard';
import CustomCard from '../../../components/solutions/CustomCard';
import { Container,Row,Col } from 'react-bootstrap';
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';
import IconCustomCard from '../../../components/solutions/IconCustomCard';
import Medios from '../../../components/img/icons/i-medios.png';
import Socios from '../../../components/img/icons/i-cooperativas.png';
import MultiCard from '../../../components/solutions/MultiCard';
import Tarjetas from '../../../components/solutions/Tarjetas';

const Aprofesionales = () =>{
    return (
        <div id='solutions' className='containerFluid'>

            <Headboard title={`Asociaciones\nprofesionales`} image={HeadImage}/>
        
            <Sublock 
            title = {`¿Querés mejorar la recaudación de las\ncuotas sociales o de afiliación?`}
            text = {`En un solo panel podés integrar todos los medios de pago\ny optimizar tu recaudación.`}
            />

            <Container className='container-body-2' style={{paddingTop:'80px', paddingBottom:'80px'}}>
                <Row>        
                    <Col md={6} xs={12} >
                        <IconCustomCard title={`Unificá, en tiempo real, el\n proceso de recaudación con\nlos sistemas de gestión`} icon={Medios} align='center'/>
                    </Col>
                    <Col md={6} xs={12}>
                        <IconCustomCard title={`Ofrecé a tus\n matriculados/socios\ntodos los medios de pago`} icon={Socios} align='center'/>
                    </Col>
                </Row>
            </Container>

            <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
                <Container className='container-body-2' >
                    <Row>
                    <Col md={6}>    
                    <CustomCard title={`Gestioná la mora\neficazmente`} text={`PayFun te permite cobrar todas las\ncuotas, aún cuando estén vencidas.\n`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                    <CustomCard title={`Página web adaptada\na tus socios`} text={`Agregá a tu página web un botón\nde pago y para agilizar tus cobros.\n`} align='left' button='off'/>
                    </Col>
                    </Row>
                </Container>
            </div>
                
            <Container className='container-body-2 neutral-div'>
                <CustomCard title={`¿No contás con una página web propia?`} text={`Nosotros nos encargamos de crear una landing page (página básica)\ncon botón de pago para tus clientes.`} align='center' button='off'/>
                <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
            </Container> 
            
            <MultiCard/>
            
            <Tarjetas text='Quiero optimizar mi recaudación'/>
            
        </div>
    )
}

export default Aprofesionales