import { Formik, Form, Field, ErrorMessage} from "formik";
import React, { useState, useRef} from 'react';
import { Container,Row,Col } from "react-bootstrap";
import * as Yup from "yup";
import ok from '../components/img/icons/ok.png';
import process from '../components/img/icons/icon-process.gif';
import error from '../components/img/icons/icon-error.png';
import ReCAPTCHA from "react-google-recaptcha";

const dniPat = '[0-9]+$';
const numPat = '[0-9p]+$';

const FormSchema = Yup.object().shape({
    
    dni: Yup.string().matches(dniPat, "Coloque un DNI/CUIT/CUIL válido")
        .required("El documento es requerido")
        .max(11, "De 8 a 11 dígitos (sin puntos)")
        .min(8, "El número de documento es incorrecto"),  
    reclamo: Yup.string().matches(numPat, "verifique el número de reclamo")
        .min(1,'verifique el número de reclamo')
        .max(11,'verifique el número de reclamo')
        .required('El nº de reclamo es requerido'),
  });

  export default function SeguimientoReclamos(){
    const[envio,setEnvio] = useState({});
    const[respuesta,setRespuesta] = useState('');
    const[temaRespuesta,setTemaRespuesta] = useState('');
    const[nocaptcha,setNocaptcha] = useState('');
    const[icon,setIcon] = useState('');
    const captchaRef = useRef(null);
    

    
    
      function handleSubmit(values) {
        const codigoToken = {'token':captchaRef.current.getValue()}
        captchaRef.current.reset();
        
        fetch (process.env.REACT_APP_GOOGLE, {
            method: 'POST',
            headers: { 
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json' 
            },
            mode: 'cors',
            body: JSON.stringify(codigoToken)
          })
        .then(res => res.json())
        .then(res => {
        
            if (res.success) { 
                setEnvio(Object.assign( envio, {'token':res.message}));

                setEnvio(Object.assign( envio, values));
                // formik.resetForm();
                    fetch (process.env.REACT_APP_SEGUIMIENTO, {
                    method: 'POST',
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json' 
                    },
                    mode: 'cors',
                    body: JSON.stringify(envio)
                  })
                  .then(res => res.json())
                  .then(res => {
                    if (res.success) { // exito
                        if(res.data[0].estado_respuesta === 'pendiente'){
                            setIcon(process)
                        }else{
                            setIcon(ok)
                        }
                        setTemaRespuesta(['Asunto del reclamo: '+respuesta,res.data[0].concepto_tema+' ('+res.data[0].concepto_subtema+')'])
                        res.data[0].devolucion_cliente?setRespuesta(['Estado: '+respuesta,res.data[0].estado_respuesta+' - Resolución: '+res.data[0].devolucion_cliente]):setRespuesta(['Estado: '+respuesta,res.data[0].estado_respuesta])

                    }else{
                        setIcon(error)
                        setRespuesta(['Hubo un error al buscar el reclamo, intente más tarde'])
                    }
                  });
            }else{
                setNocaptcha('Por favor tilde la casilla de verificación para procesar el pedido')
            }
          });
            }
    
    return(
        <Formik
        initialValues={{
          dni: "",
          reclamo: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        >
            {(formData) => (
                <Form>
                    <div id='seguimiento' className='containerFluid' >
                        <Container className='container-body-2 mt-5'>
                            <Row className='d-flex justify-content-center mt-5'>
                                <Col md={12} className='text-center mb-2 mt-5'>
                                    <h2>Seguimiento de reclamos</h2>
                                </Col>
                                <Col md={12} className='text-center mb-5'>
                                    <p>Coloque el DNI/CUIT O CUIL de la tarjeta o, si hizo un reclamo por problemas en una cuenta, el del titular de la cuenta origen y el número que le fué informado al mail para conocer el estado del reclamo.</p>
                                </Col>
                                <Col md={4} className='mb-4'>
                                    <label className="form-label" htmlFor='dni'>DNI/CUIT/CUIL (sin puntos)*</label>
                                    <Field id="dni" className='form-control' name="dni" type="number" />
                                    <ErrorMessage component="div" className='errorMsj' name="dni"/>
                                </Col>
                                <Col md={4} className='mb-4'>
                                    <label className="form-label" htmlFor='reclamo'>Nº de Reclamo *</label>
                                    <Field id="reclamo" className='form-control' name="reclamo" type="text" />
                                    <ErrorMessage component="div" className='errorMsj' name="reclamo"/>
                                </Col>
                                <Col md={12} className='d-flex justify-content-center mt-5 mb-5'>{

                                respuesta!==''?
                                <div>
                                <div className='mt-2'>
                                    <h4>{temaRespuesta}</h4>
                                </div><br/>
                                <div className="alert alert-info d-flex align-items-center" role="alert">
                                <img src={icon} width ='30' height='auto' alt='estado'/>
                                
                                <div className='mt-2'>
                                    <br></br><p><strong>{respuesta}</strong></p>
                                </div>
                                </div>
                                </div>
                                :
                                <Row>
                                    <Col md={12} className='d-flex justify-content-center'>
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
                                    </Col>
                                    <Col md={12} className='d-flex justify-content-center mb-3'>
                                        <p><strong>{nocaptcha}</strong></p>
                                    </Col>
                                    <Col className='d-flex justify-content-center mb-3'> 
                                        <Row>
                                            <Col md={6} xs={6}>
                                            <button className="btn btn-primary" type="submit" style={{background:'#594184',border:'none'}}>Enviar</button>
                                            </Col>
                                            <Col md={6} xs={6}>
                                            <button className="btn btn-secondary" style={{background:'#ffbad5',border:'none'}} type="reset">Limpiar</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                }</Col>
                                <Col md={12} className='d-flex justify-content-center mt-1 mb-3 text-center'>
                                    <p>Si la respuesta obtenida no fuera satisfactoria, usted puede presentar su reclamo a través del área de Protección al Usuario Financiero de que dispone el BCRA, ingresando en <a href='http://www.bcra.gob.ar/BCRAyVos/reportes.asp' target='_blank' rel='noreferrer'>este enlace.</a></p>
                                </Col>
                            </Row>
                         </Container>
                    </div>
                </Form>
            )}
        </Formik>
    ) 
}