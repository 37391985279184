import {Col,Container,Row} from 'react-bootstrap';
import check from '../../components/img/photos/check.png';
import demo from '../../components/img/photos/panel.png';
import BotonSolicitud from '../../components/all/BotonSolicitud';



export default function Seccion3(){

    const text=[
        {texto:'Ahorrás costos de comisión.'},
        {texto:'Optimizás tiempo de gestión.'},
        {texto:'Conciliaciones incluídas en el servicio.'},
        {texto:'Asistencia personalizada.'},
    ]

    
    return(

        <section id='inicio3'>
            <div className='container-fluid cyan-back seccion-centrado'>
            <Container className='container-body-2'>
                <Row className='d-flex justify-content-center'>
                    <Col md={7} xs={12} data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                        <div className='seccion-2-texto'>
                            <h5>Ahora cobrar online es más fácil y seguro</h5><br/>

                            {text.map((e, index) =>
                            <Col md={10}  key={`list-${index}`} className='mx-auto'  data-aos="fade-up" data-aos-offset="200" data-aos-delay={index*100} data-aos-duration="1000"><p><img src={check} alt='check1'/> {e.texto}</p></Col>
                            )}

                            <div className='cont-centrado'>
                            <BotonSolicitud positivo='true'/> 
                            </div>


                        </div>
                    </Col>
                    <Col md={5} xs={12} style={{minHeight:'300px'}} data-aos="fade" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                    <img className="img-fluid image-demo" src={demo} alt="demo_panel"/>
                    </Col>
                </Row>
            </Container>
            </div>
        </section>
    ) 
}