import Headboard from '../../../components/solutions/Headboard'
import HeadImage from '../../../components/img/photos/solutions/suscripciones.png'
import Sublock from '../../../components/solutions/Sublock'
import Tarjetas from '../../../components/solutions/Tarjetas'
import MultiCard from '../../../components/solutions/MultiCard'
import CustomCard from '../../../components/solutions/CustomCard'
import BackgrounWhiteDiv from '../../../components/img/solutions/background-cards.jpg';
import { Container,Row,Col } from 'react-bootstrap'

const Aprofesionales = () =>{
    return (
        <div id='solutions' className='containerFluid'>

        <Headboard title={`Suscripciones`} image={HeadImage}/>

        <Sublock
        title = {`Ahora los cobros automáticos son más fáciles`}
        text = {`Con PayFun, ofrecés comodidad y rapidez en los pagos recurrentes de tus clientes.`}
        />

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                    <CustomCard title={`Gestioná la mora eficazmente`} text={`PayFun te permite cobrar todas las cuotas,\naún cuando estén vencidas.`} align='center' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className='containerFluid white-div' style={{backgroundImage:`url(${BackgrounWhiteDiv})`}}>
            <Container className='container-body-2' >
                <Row>
                    <Col md={6}>    
                        <CustomCard title={`Integrá tu sistema de gestión\nactual con nuestros módulos`} text={`Disponemos de potentes y diversas API's\nque se integran fácilmente a tu plataforma.`} align='left' button='off'/>
                    </Col>
                    <Col md={6}>    
                        <CustomCard title={`Cobrá en tu propio sitio web\nintegralmente`} text={`Ofrecé un botón de pago en tu página web,\ningresando desde un celular o PC.`} align='left' button='off'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className='containerFluid neutral-div'>
            <Container className='container-body-2' >
                <Row>
                    <Col md={12}>    
                    <CustomCard title={`Cobrá con débito automático`} text={`Con PayFun, gestioná rápidamente tus pagos recurrentes.`} align='center' button='on'/>
                    </Col>
                </Row>
            </Container>
        </div>

        <Container className='container-body-2 neutral-div'>
            <MultiCard/>
        </Container>

        <Tarjetas text='Quiero optimizar mi recaudación'/>

        </div>
            )
}

export default Aprofesionales