import {Col,Container,Row} from 'react-bootstrap';
import girl from '../img/chica-landing-page-payfun.png'



export default function Servicios(){

    const text=[
        {texto:'LINK DE PAGO'},
        {texto:'BOTÓN EN SITIO WEB'},
        {texto:'PAGOS QR'},
        {texto:'ÓRDENES DE PAGO'},
        {texto:'TOTEMS INTERACTIVOS'},
        {texto:'PAGO POR CÓDIGO DE BARRA'},
    ]

    
    return(

        <section id='servicios' className='mt-mb-5 mb-mb-5 mb-xl-1 mt-xl-1'>
            <div className='container-fluid seccion-centrado'>
            <Container className='container-body-2'>
                <Row >
                    <Col md={6} xs={12} className='d-flex flex-column justify-content-around text-center'>
                        {text.map((e, index) =>
                            <Col md={10} xs={12}  key={`list-${index}`} className='mx-auto integrateServices mb-1'>
                                {e.texto}
                            </Col>
                        )}
                    </Col>
                    <Col md={6} xs={12} style={{minHeight:'300px'}} className='d-none d-md-block'>
                    <img className="img-fluid image-demo" src={girl} alt="imagen-landing-page"/>
                    </Col>
                </Row>
            </Container>
            </div>
        </section>
    ) 
}