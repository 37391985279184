import notFound from '../components/img/icons/notFound.png';

export default function NotFound(){
    return(
    <div className='notFound'>
        <img width='130' height='130' src={notFound} alt='notFound'/>
        <h1>¡¡La página que buscas no existe!!</h1>
        <p>Si estaba en esta ubicación probablemente se ha movido.<br/>Te sugerimos que busques en el menú superior lo que necesitas.</p>
    </div>
    ) 
}