import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import ServiceIconCard from '../components/services/ServiceIconCard';
import contracargo from '../components/img/icons/i-contracargo.png';
import disputa from '../components/img/icons/i-disputa.png';
import { useState } from "react";
import BotonCustomLink from "../components/all/BotonCustomLink";

export default function Contracargos(){

    const [item, setItem] = useState(false);
    return(
        <div id='contracargos' className='containerFluid'>
            <Container className='container-body-2'>
                <Row>
                    <Col md={12} className='text-center mb-2 mt-5'>
                        <h2>Contracargos</h2>
                    </Col>
                    <Col md={12} className='text-center mb-5 mt-3' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000">
                        <p>De vez en cuando, algo sale mal con un pedido. Esto es algo normal al hacer negocios y, a menudo, es el resultado de un malentendido o error humano. Cuando esto sucede, los compradores pueden tomar medidas abriendo una disputa o presentando un reclamo.</p>
                    </Col>
                    <Col md ={12} className='mb-5 mt-5'>
                        <Row className="justify-content-between">    
                            <Col md ={5}>   
                                <ServiceIconCard title={`¿Qué es un contracargo?`} text={`Un contracargo es lo que sucede\ncuando un cliente que ha\nrealizado una compra presenta\nuna queja a su compañia de\ntarjeta de crédito.`} icon={contracargo}/>
                            </Col>
                            <Col md ={5}>   
                                <ServiceIconCard title={`¿Qué es una disputa\ncon un cliente?`} text={`Un cliente puede presentar una\ndisputa sobre la validez o calidad\nde las compras directamente a\ntravés de su cuenta PayFun.`} icon={disputa}/>
                            </Col>
                        </Row>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mt-5 mb-5 d-grid gap-2' data-aos="fade-up" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000">
                    <Button className='contracargo-button' onClick={() => setItem(!item)} aria-controls="example-collapse-text" aria-expanded={item} style={{textAlign:'left'}}>
                    {item===true?'▲':'▼'} Razones comunes para contracargos y disputas
                    </Button>
                    <Collapse in={item} className='contracargo-body'>
                        <div id="razones-contracargos" >
                        En la mayoría de los casos, la forma más fácil de resolver una disputa es que los compradores y vendedores trabajen juntos para descubrir que sucedió y resolver sus diferencias. Esto puede ayudar a evitar que se retengan las retenciones en tu cuenta y puede ser muy útil para mantener contentos a tus clientes.<br/><br/>
                        <ul>
                            <li><strong>Muestra buena fe.</strong> Comienza la conversación con una mente abierta y escucha lo que el comprador tiene que decir antes de hacer suposiciones. muchos problemas son el resultado de una mala comunicación y un simple error humano.</li>
                            <li><strong>Sé constructivo.</strong> Deje en claro a tu comprador que deseas encontrar una solución, así tu cliente generalmente corresponderá. Esta temprana muestra de respeto mutuo hará que el resto de la conversación sea más productiva.</li>
                            <li><strong>Mantente enfocado en la creación de soluciones.</strong> Si tu paciencia se está agotando, resiste la tentación de contestar con enojo a tu cliente, esto hará más difícil que se llegue a un acuerdo.</li>
                            <li><strong>Piensa a largo plazo.</strong> No toda batalla vale ser peleada. Si le das al comprador una respuesta favorable, seguramente eso te abrirá la puerta para más oportunidades de negocio mañana.</li>
                            <li><strong>Disputas anticipadas.</strong> El Centro de Resolución te permite ingresar mensajes que verán todos tus compradores antes de que puedan abrir una disputa. Por ejemplo, si publicas un mensaje que dice: "Todos los envíos desde Miami están actualmente retrasados debido al huracán", le das a tu comprador la oportunidad de comprender la situación antes de presentar una disputa.</li>
                            
                        </ul>
                        </div>
                    </Collapse>
                    </Col>
                    {/* ------------------------------------------ */}
                    <Col md={12} className='mb-5 mt-2 text-center' data-aos="fade-up" data-aos-offset="50" data-aos-delay="100" data-aos-duration="1000">
                        <BotonCustomLink text='Volver' link='/reclamos' tipo='blue'/><br/><br/><br/>
                    </Col>
                </Row>
            </Container>       
        </div>
    ) 
}