import {Container,Col,Row} from 'react-bootstrap';
import seccion1FondoCabecera from '../../components/img/photos/seccion1-cabecera-background.png'
import imgCabeceraInicio from '../../components/img/photos/cabecera-inicio.png'
import BotonSolicitud from '../../components/all/BotonSolicitud';

export default function Seccion1(){
    return(
    <section id='headboard'>
        <div className="container-fluid headboard blue-back" style={{backgroundImage:`url(${seccion1FondoCabecera})`}}>
            <Container>
                <Row>
                    <Col md={7} xs={12} className='texto'  data-aos="fade-right" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000" style={{color:'white'}}>
                    <h1>La plataforma de pago especializada en grandes empresas y entidades</h1>
                            <h3>Pagos simples y seguros.<br/>Porque vos y el mundo así lo necesitan</h3>        
                    <br/>
                        <BotonSolicitud positivo='true'/>
                    </Col>
                    <Col md={5} xs={12}  data-aos="fade-left" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                    <img style={{'marginTop':'30px'}} className='img-fluid' src={imgCabeceraInicio} alt='cabecera-inicio'/>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
    ) 
}