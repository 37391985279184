import {Container,Col,Row, Card} from 'react-bootstrap';
import woocommerce from '../img/photos/woocommerce.png'
import oscommerce from '../img/photos/oscommerce.png'
import magento from '../img/photos/magento.png'
import odoo from '../img/photos/odoo.png'
import prestashop from '../img/photos/prestashop.png'
import shopware from '../img/photos/shopware.png'
import enchufe from '../img/icons/enchufe.png'

export default function Commerce(){
    return(
        <div className='containerFluid commerce'>
        <Container className='container-body-2 '>
                <Row>
                <Col md={8}  data-aos="fade-up" data-aos-offset="200" data-aos-duration="1000"><h2 className='parrafo'>{`Integrá fácilmente tu E-commerce\ncon nuestros módulos de servicio`}</h2></Col>
                <Col md={8}  data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000"><p className='parrafo'>{`Disponemos de potentes y diversas API's o pluggins\nque se adaptan a tu E-commerce.`}</p></Col>
               <Col md={12}><br/>
                <Col md={12} className='enchufe' data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                <img className='img-fluid' src={enchufe} alt={enchufe}/>
                </Col>
                <Card data-aos="fade-up" data-aos-offset="200" data-aos-delay="500" data-aos-duration="1000">
                    <Card.Body>
                        <Row className='d-flex justify-content-center align-items-center'  data-aos="fade-up" data-aos-offset="200" data-aos-delay="1000" data-aos-duration="1000">
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={woocommerce} alt={woocommerce}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={magento} alt={magento}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={prestashop} alt={prestashop}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={oscommerce} alt={oscommerce}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={odoo} alt={odoo}/></Col>
                            <Col md={4} xs={6} className='marca'><img className='img-fluid' src={shopware} alt={shopware}/></Col>
                        </Row>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </Container>          
        </div>
    )

}